/**
 * Datepicker UI theming
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.ui-datepicker {
	background: $white;
	box-shadow: 0 2px 40px rgba($black, 0.15);
}

html.pum-open.pum-open-overlay.pum-open-scrollable body > [aria-hidden].ui-datepicker {
	padding-right: 0;
}

.ui-datepicker-calendar thead,
.ui-datepicker-header {
	color: $white;
}

.ui-datepicker-calendar,
.ui-datepicker-header {
	border-bottom: $border-width solid $black;
}

.ui-datepicker-header {
	position: relative;
	border-top: $border-width solid $black;
	background: $black;
	text-align: center;

	select {
		margin: 0 bsu(1/4);
		padding: bsu(1/4) rem-calc(20) bsu(1/4) bsu(1/2);
		width: auto;
		background-image: url("../images/chevron-down-white.svg");
		background-color: transparent;
		background-position: right;
		background-size: rem-calc(12);
		border: none;
		text-transform: uppercase;
		cursor: pointer;
	}

	option {
		color: $black;
	}
}

.ui-datepicker-title {
	@include flex-row();
	justify-content: center;
}

.ui-datepicker-next,
.ui-datepicker-prev {
	position: absolute;
	top: 0;
	width: rem-calc(20);
	height: 100%;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	font-size: 0;
	cursor: pointer;
}

.ui-datepicker-prev {
	left: bsu(1/2);
	background-image: url("../images/chevron-square-left.svg");
}

.ui-datepicker-next {
	right: bsu(1/2);
	background-image: url("../images/chevron-square-right.svg");
}

.ui-datepicker-buttonpane {
	// display: none; // actually, just kill it for now
	padding: bsu(1/4);
	background: $primary;

	button {
		margin-right: bsu(1/4);
		padding: rem-calc(5) rem-calc(16);
		background: $secondary;
	}
}

.ui-datepicker-calendar {
	font-size: 0.875rem;

	thead {
		background: $primary;
		border: none;
	}

	td a,
	th {
		text-align: center;
		padding: rem-calc(6) rem-calc(10); //0.35rem 0.66rem;
	}

	td a {
		display: block;
		transition-property: background-color;
		@include transition-ease-in-out;

		&.ui-state-active,
		&:hover {
			color: $white;
		}

		&:hover {
			background: $black;
		}

		&.ui-state-active {
			background: $primary;
			font-weight: 700;
		}
	}

	tr:nth-child(even) {
		background-color: $gray-light;
	}

	a {
		color: $black;
	}
}
// .ui-datepicker-calendar
