/**
 * Details Slider Section styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.details-slider {
	.details-slider-wrapper {
		@include breakpoint (menu-break) {
			display: flex;
			flex-direction: row;
		}
		@include breakpoint (vlarge) {
			@include wrap(1800px);
		}
	}

	.details {
		display: flex;
		justify-content: center;
		z-index: -1;
		background-color: $secondary;
		@include breakpoint (xlarge) {
			width: 40%;
			min-height: 740px;
		}
		@include breakpoint (xxlarge) {
			width: 60%;
		}

		.wrap {
			padding: 85px 0 71px;
			@include breakpoint (xlarge) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}

		h2,
		ul {
			color: $white;
			@include breakpoint (menu-break) {
				max-width: 377px;
				padding-left: 20px;
			}
			@include breakpoint (xxlarge) {
				max-width: 577px;
			}

			li {
				margin: bsu(1/4) 0;
			}
		}

		h2 {
			margin-bottom: 18px;
			@include breakpoint (menu-break) {
				font-size: 34px;
				margin-bottom: 28px;
			}
			@include breakpoint (xxlarge) {
				font-size: 54px;
				margin-bottom: 28px;
			}
		}
	}
}

#details-slider {
	.slider {
		top: 45px;
		@include breakpoint (menu-break) {
			top: 133px;
		}
		@include breakpoint (xlarge) {
			right: -75px;
		}
		@include breakpoint (xxlarge) {
			right: -75px;
		}
		@include breakpoint (1600px) {
			right: -200px;
		}
		@include breakpoint (1600px) {
			right: -150px;
		}
	}
}
