/**
 * Thank You Page Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.thank-you {
	.banner {
		min-height: 100vh;

		&:after {
			@include overlay(-1);
			background: rgba($secondary, 0.8);
		}

		.wrap {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		h1,
		h2 {
			color: $white;
			padding: bsu(1);
		}
	}
}
