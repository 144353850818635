/**
 * Slogan section
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.slogan {
	.logo-stacked {
		display: flex;
		justify-content: center;
		margin: 55px 0 42px;
		@include breakpoint (menu-break) {
			margin: 140px 0 65px;
		}

		img {
			width: 107px;
			height: 100px;
			@include breakpoint (menu-break) {
				width: 199px;
				height: 188px;
			}
		}
	}

	.entry-content {
		display: flex;
		justify-content: center;

		blockquote {
			max-width: 260px;
			font-family: $font-serif-bold;
			font-size: 24px;
			font-style: normal;
			color: $footer-color;
			text-align: center;
			margin-bottom: 44px;
			@include breakpoint (menu-break) {
				max-width: 800px;
				font-size: 54px;
				line-height: 70px;
				letter-spacing: -2px;
			}
		}
	}

	.team-image {
		margin-bottom: -8px;
		@include breakpoint (vvlarge) {
			@include wrap(1800px);
		}

		img {
			min-width: 100%;
		}
	}
}
