/**
 * Contact Page Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.contact-page {
	// Banner (custom for contact page layout)
	header {
		height: 170px;
		position: relative;
		display: flex;
		align-items: center;
		overflow: hidden;
		@include breakpoint(menu-break) {
			height: 490px;
		}

		.wrap {
			max-width: 1800px;

			h1,
			h2 {
				color: white;
			}

			h1 {
				font-size: 50px;
				letter-spacing: -2.5px;
				@include breakpoint (menu-break) {
					font-size: 94px;
				}
			}

			h2 {
				font-family: $font-serif-italic;
				font-size: $global-font-size;
				max-width: 211px;
				margin-top: 15px;
				font-size: 21px;
				@include breakpoint (menu-break) {
					font-size: 36px;
					max-width: none;
					margin-top: 20px;
				}
			}
		}
		// background image
		img {
			z-index: -1;
		}
	}

	section {
		@include breakpoint (menu-break) {
			display: flex;
			flex-direction: row;
		}
	}

	.contact-info {
		margin-top: 30px;
		@include breakpoint (menu-break) {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			width: 35%;
			margin: 135px 0 0;
		}

		h2 {
			display: none;
			font-size: 54px;
			@include breakpoint (menu-break) {
				display: block;
				max-width: 400px;
				margin-bottom: 25px;
			}
		}

		.icon-links {
			@include breakpoint (menu-break) {
				max-width: 400px;
			}

			a {
				display: flex;
				align-items: center;
				font-family: $font-serif-bold;
				font-size: 14px;
				color: $form-text;
				line-height: 2.29;
				margin: 15px 0;
				@include breakpoint (menu-break) {
					margin: 37px 0;
				}
				@include breakpoint (xlarge) {
					font-size: 21px;
				}
			}

			.circle {
				width: 30px;
				height: 30px;
				background-color: $primary;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 9px;
				@include breakpoint (xlarge) {
					width: 40px;
					height: 40px;
				}
			}

			svg {
				color: $white;
			}
		}
	}

	.community-form {
		height: auto;
		position: relative;
		@include breakpoint (menu-break) {
			width: 65%;
			margin: 0 0 40px;
		}
		@include breakpoint (xxlarge) {
			background-color: $primary;
			min-height: 800px;
			margin: 0;
		}

		#contact-form {
			background-color: $white;
			@include breakpoint (menu-break) {
				margin: 0;
			}
			@include breakpoint (xxlarge) {
				min-width: 870px;
				position: absolute;
				top: -210px;
				left: 50%;
				transform: translateX(-50%);
			}

			.wrap {
				@include breakpoint (xxlarge) {
					width: 100%;
				}
			}

			header {
				height: 110px;
				background-color: $off-white;
				@include breakpoint (menu-break) {
					height: 210px;
				}
			}

			h2 {
				font-size: 24px;
				margin: 16px 0 3px;
				@include breakpoint (menu-break) {
					font-size: 44px;
					margin: 50px 0 6px;
					padding: 0 20px;
				}
			}

			h3 {
				max-width: 156px;
				margin: 3px 0 16px;
				@include breakpoint (menu-break) {
					max-width: none;
					font-size: 21px;
					margin: 6px 0 50px;
				}
			}

			form {
				margin-top: 26px;
				@include breakpoint (xxlarge) {
					@include wrap;
					margin-top: 71px;
					margin-bottom: 69px;
				}
			}
		}
	}
}
