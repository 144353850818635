/**
 * Slider Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.slider {
	height: 182px;
	position: relative;
	@include wrap(300px);
	@include breakpoint (medium) {
		@include wrap(600px);
		height: 474px;
	}
	@include breakpoint (menu-break) {
		width: 780px;
		height: 474px;
	}

	.carousel-cell {
		width: 100%;
		height: 182px;
		@include breakpoint (medium) {
			width: 600px;
			height: 474px;
		}
	}

	.flickity-prev-next-button {
		cursor: pointer;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-color: $primary;
		border-radius: 0;
		padding: 0 5px;
		@include breakpoint (medium) {
			width: 50px;
			height: 110px;
		}

		&.previous {
			left: -14px;
			@include breakpoint (medium) {
				left: -24px;
			}
		}

		&.next {
			right: -14px;
			@include breakpoint (medium) {
				right: -24px;
			}
		}

		.flickity-button-icon {
			fill: $white;
		}

		svg {
			width: 12px;
			height: 25px;
			@include breakpoint (medium) {
				width: 25px;
				height: 67px;
			}
		}
	}
}
