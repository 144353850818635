/**
 * Breadcrumb (via Yoast)
 *
 * @since 1.0.0
 */
.breadcrumb {
	.breadcrumb_last,
	a,
	span {
		display: inline-block;
		font-family: $font-sans-serif;
		font-size: $global-font-size;
		font-weight: 300;
	}

	.breadcrumb_last,
	a {
		padding-left: bsu(1/3);
		padding-right: bsu(1/3);
		color: $gray-dark;
	}

	a {
		transition-property: color;
		@include transition-ease-in-out;

		&:hover {
			color: $primary;
		}
	}

	.breadcrumb_last {
		cursor: default;
	}

	.svg-inline--fa {
		color: $gray;
	}
}
