/**
 * Section with text and image collage
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.image-collage {
	display: flex;
	flex-direction: column;
	margin-top: bsu(2);
	@include wrap(1800px);
	@include breakpoint (medium) {
		margin-bottom: bsu(2);
	}
	@include breakpoint (menu-break) {
		flex-direction: row;
	}
	@include breakpoint (xxlarge) {
		justify-content: center;
		margin-top: bsu(5);
		margin-bottom: bsu(5);
	}

	.intro {
		position: relative;
		@include breakpoint (xxlarge) {
			margin-left: 150px;
		}
	}

	.icon {
		display: none;
		@include breakpoint (xxlarge) {
			display: block;
			position: absolute;
			left: -150px;
			margin-top: bsu(2/3);
		}

		img {
			max-width: 130px;
			height: 100%;
		}
	}

	.entry-content {
		blockquote {
			p {
				@include breakpoint (menu-break) {
					font-size: 21px;
				}
			}
		}

		h2 {
			@include breakpoint (menu-break) {
				font-size: 66px;
				max-width: 525px;
			}
		}

		p {
			@include breakpoint (menu-break) {
				max-width: 525px;
			}
		}
	}

	.images-small {
		max-width: 350px;
		min-height: 315px;
		position: relative;
		margin: 50px auto;
		@include breakpoint (small) {
			margin: 50px auto 0;
		}
		@include breakpoint (medium) {
			max-width: 550px;
			min-height: 375px;
		}
		@include breakpoint (large) {
			max-width: 700px;
		}

		.feature-image,
		.left-image,
		.middle-image {
			position: absolute;
			display: flex;
			flex-direction: column;

			img {
				max-width: 65%;
				@include breakpoint (medium) {
					max-width: 100%;
				}
				@include breakpoint (menu-break) {
					max-width: 65%;
				}
				@include breakpoint (xxlarge) {
					max-width: 85%;
				}
			}
		}

		.left-image {
			top: 0;
			left: 0;
			@include breakpoint (xxlarge) {
				left: -150px;
			}

			img {
				@include breakpoint (large) {
					width: 457px;
				}
				@include breakpoint (1650px) {
					max-width: 100%;
					height: 300px;
				}
			}
		}

		.middle-image {
			align-items: flex-end;
			top: 36px;
			right: 0;
			@include breakpoint (xxlarge) {
				top: 13px;
			}
			@include breakpoint (xxlarge) {
				right: -15px;
			}
			@include breakpoint (vlarge) {
				top: 138px;
			}

			img {
				@include breakpoint (xxlarge) {
					max-width: 100%;
				}
			}
		}

		.feature-image {
			bottom: 0;
			left: 47px;

			img {
				max-width: 65%;
				@include breakpoint (menu-break) {
					max-width: 100%;
				}
			}
		}

		.feature-image {
			@include breakpoint (medium) {
				display: none;
			}
		}
	}

	.images-large {
		display: none;
		flex-direction: column;
		justify-content: center;
		@include breakpoint (medium) {
			display: flex;
			margin: 0 auto;
		}
		@include breakpoint (menu-break) {
			min-width: 544px;
			margin: 0 0 0 75px;
		}
	}
}

.home {
	#content {
		.entry-content {
			h3 {
				margin: bsu(2/3) 0 0;
			}
		}
	}
}
