/**
 * Typography
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
*::selection {
	background: rgba($black, 0.9);
	color: $white;
	text-shadow: none;
}

*::-moz-selection {
	background: rgba($black, 0.9);
	color: $white;
	text-shadow: none;
}

body,
html {
	font-size: $global-font-size;
}

body {
	line-height: $line-height-body;
	color: $black;
	font-family: $font-sans-serif;
	@include font-smoothing;
}
/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: $secondary;
	font-family: $font-serif-bold;
	font-weight: normal;
}

h1 {
	font-size: 40px;
	line-height: 1.05;
	@include breakpoint (menu-break) {
		font-size: 134px;
		line-height: 1.01;
	}
}

h2 {
	font-size: 30px;
	line-height: 1.15;
	@include breakpoint (menu-break) {
		font-size: 68px;
		line-height: 1.13;
	}
}

h3 {
	font-size: 24px;
	line-height: 1.17;
	@include breakpoint (menu-break) {
		font-size: 54px;
		line-height: 1.3;
	}
}

h4 {
	font-size: 21px;
	line-height: 1.14;
	@include breakpoint (menu-break) {
		font-size: 30px;
		line-height: 1.17;
	}
}
/**
 * Paragraphs
 */
p {
	color: $gray;
	font-family: $font-sans-serif;
	font-weight: 300;
	font-size: $global-font-size;
	line-height: 1.5;
	margin: bsu(2/3) 0 0;
	@include breakpoint (menu-break) {
		font-size: 21px;
		line-height: 1.52;
	}

	& > small,
	.detail {
		color: $primary;
		line-height: 1.25;
		font-family: $font-serif-italic;
	}

	a {
		@include inline-link;
	}
}
/**
 * Anchors
 */
a,
a:visited {
	color: $primary;
	text-decoration: none;
	font-family: $font-sans-serif;
	font-size: $global-font-size;
	font-weight: 300;
	transition-property: color;
	@include transition-ease-in-out;

	&:hover:not([role="button"]) {
		color: $primary-lighter;
	}
}
/**
 * Blockquotes & Cites
 */
blockquote,
q {
	color: $primary;
	font-family: $font-serif-italic;
	font-size: 18px;
	line-height: 1.67;
	margin: 0;
	font-style: italic;
	@include breakpoint (menu-break) {
		font-size: 26px;
		line-height: 1.92;
	}

	&:after,
	&:before {
		content: "";
	}

	p {
		color: $primary;
		font-family: $font-serif-italic;
		font-size: 18px;
		line-height: 1.67;
		margin: bsu(2/3) 0 0;
		font-style: italic;
		@include breakpoint (menu-break) {
			font-size: 26px;
			line-height: 1.92;
		}

		&:after,
		&:before {
			content: "";
		}
	}
}
/**
 * Horizontal Rule
 */
hr {
	margin: bsu(2) 0;
	border: none;
}
/**
 * Address
 */
address {
	font-style: normal;
}
/**
 * Lists
 */
ol,
ul {
	color: $gray;
	font-size: 14px;
	font-weight: 300;
	line-height: 2.14;
	margin: 0;
	padding: 0;
	list-style-type: none;
	@include breakpoint (menu-break) {
		font-size: 18px;
		line-height: 2.22;
	}
}

ol.numbered,
ul.bulleted {
	@include list;
}

ul.bulleted {
	@include bulleted-list;
}

ol.numbered {
	@include numbered-list;
}
/**
 * WYSIWYG content output
 */
.entry-content {
	@include wysiwyg;

	&:first-child {
		margin-top: 44px;
		@include breakpoint (menu-break) {
			margin-top: 114px;
		}
	}

	&:last-child {
		margin-bottom: 44px;
		@include breakpoint (menu-break) {
			margin-bottom: 114px;
		}
	}
}
/**
* Font face
*/
@font-face {
	font-family: 'Andada Regular';
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/andada-regular.otf") format("opentype"), url("../fonts/andada-regular.woff") format("woff");
}
@font-face {
	font-family: 'Andada Bold';
	font-style: normal;
	font-weight: bold;
	src: url("../fonts/andada-bold.otf") format("opentype"), url("../fonts/andada-bold.woff") format("woff");
}
@font-face {
	font-family: 'Andada Italic';
	font-style: italic;
	font-weight: normal;
	src: url("../fonts/andada-italic.otf") format("opentype"), url("../fonts/andada-italic.ttf") format("truetype"), url("../fonts/andada-italic.woff") format("woff");
}
