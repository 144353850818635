/**
 * About Page Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.about-page {
	.image-quote {
		.quote-divider {
			@include breakpoint (768px) {
				width: 85%;
			}
		}

		.quote-wrapper {
			h6 {
				@include breakpoint (768px) {
					font-size: 45px;
					line-height: 60px;
					max-width: 100%;
					text-align: center;
				}
				@include breakpoint (menu-break) {
					font-size: 65px;
					line-height: 80px;
					margin: 25px 0;
				}
			}
		}
	}
}
