/**
 * Services Listing Section styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.services-listing {
	background-color: $secondary;
	padding: 40px 0;

	.services-listing-wrap {
		@include wrap(1800px);
	}

	.services-listing-title {
		@include wrap(1800px);
		text-align: center;
		padding: 45px 0;
		@include breakpoint (menu-break) {
			text-align: left;
		}

		h2 {
			color: $white;
			@include breakpoint (menu-break) {
				letter-spacing: -2px;
			}
		}
	}

	.card-grid {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		@include breakpoint (medium) {
			flex-direction: row;
		}
	}

	.service-card {
		display: flex;
		flex-direction: column;
		min-width: 281px;
		max-width: 281px;
		min-height: 283px;
		max-height: 283px;
		margin: 10px 15px;
		background-color: $white;
		box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.35);
		@include breakpoint (menu-break) {
			min-width: 375px;
			max-width: 375px;
			min-height: 405px;
			max-height: 405px;
			margin: 20px 18px;
		}
		@include breakpoint (vvlarge) {
			min-width: 403px;
			max-width: 403px;
		}
	}

	.card-image {
		width: 100%;
		height: 200px;
		position: relative;
		overflow: hidden;
		@include breakpoint (menu-break) {
			height: 290px;
		}
	}

	.card-text {
		width: 100%;
		height: 84px;
		background-color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		@include breakpoint (menu-break) {
			height: 115px;
		}

		h2 {
			font-size: 21px;
			@include breakpoint (menu-break) {
				font-size: 30px;
			}
		}

		p {
			font-family: $font-serif-italic;
			font-size: $global-font-size;
			color: $primary;
			margin-top: 5px;
			@include breakpoint (menu-break) {
				font-size: 21px;
			}
		}
	}
}
