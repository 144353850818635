/**
 * Contact form styling for form on all pages
 * except contact page--that form styling is under pages/contact
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.contact-form {
	margin-top: 45px;
	margin-bottom: 45px;
	@include breakpoint (menu-break) {
		margin: 150px 0;
	}

	.form-wrap {
		@include wrap(1170px);
		@include breakpoint (xxlarge) {
			width: 100%;
		}
	}

	header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		h2,
		h3,
		img {
			margin: 15px 0;
		}

		img {
			width: 64px;
			height: 64px;
			@include breakpoint (menu-break) {
				width: 117px;
				height: 117px;
				margin-top: 0;
				margin-bottom: 25px;
			}
		}

		h2 {
			@include breakpoint (menu-break) {
				font-size: 54px;
			}
		}

		h3 {
			font-family: $font-serif-italic;
			font-size: $global-font-size;
			color: $primary;
			@include breakpoint (menu-break) {
				font-size: 21px;
			}
		}
	}

	form {
		margin-top: 35px;
	}

	.gform_footer {
		display: flex;
		justify-content: center;

		button[type="submit"] {
			width: 90%;
			display: flex;
			justify-content: center;
			@include breakpoint (medium) {
				width: 267px;
			}
		}
	}
}
