/**
 * Variables
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */

/**
 * Primary & Secondary Colors
 */
$primary-lighter    : #99a459;
$primary-light      : #87914e;
$primary            : #828c4e;
$primary-dark       : #666e3c;
$primary-darker     : #474f20;
$secondary-lighter  : #4e5565;
$secondary-light    : #393e4a;
$secondary          : #2b2f38;
$secondary-dark     : #20232a;
$secondary-darker   : #15171c;

/**
 * Neutral & Support Colors
 */
$form-text          : #9b9b9b;
$form-border        : #bfbfbf;
$footer-color       : #2a2e37;
$footer-copyright   : #a3a4a7;
$red                : #fa0f48;
$gray-dark          : #2d2d2d;
$gray               : #6e6e6e;
$gray-light         : #eaeaea;
$white              : #ffffff;
$off-white          : #f6f6f6;
$black              : #000000;
$transparent        : trans();


/**
 * Gradients
 */
$gradient-dark-vertical    : linear-gradient(to bottom, rgba(119,128,69,1) 0%,rgba(71,79,32,1) 100%);
$gradient-light-vertical   : linear-gradient(to bottom, rgba(119,128,69,1) 0%,rgba(153,164,89,1) 100%);
$gradient-dark-horizontal  : linear-gradient(to right, rgba(119,128,69,1) 0%,rgba(71,79,32,1) 100%);
$gradient-light-horizontal : linear-gradient(to right, rgba(119,128,69,1) 0%,rgba(153,164,89,1) 100%);


/**
 * Fonts
 */
$line-height-body   : 1.6667;
$line-height-mid    : 1.714;
$line-height-small  : 1.333;
$line-height-xsmall : 1.2;
$global-font-size   : 16px;
$font-sans-serif    : 'Nunito Sans', sans-serif;
$font-serif-regular : 'Andada Regular', serif;
$font-serif-bold    : 'Andada Bold', serif;
$font-serif-italic  : 'Andada Italic', serif;


/**
 * Spacing
 */
$bsu: 1.6667rem;


/**
 * Misc heights/widths
 */
$wrap-max           : 1200px;
$wrap-width         : 90%;
$header-height      : 70px;
$header-height-desk : 100px;


/**
 * Borders
 */
$border-width: 4px;
