/**
 * Column Shortcodes Plugin Style Overrides
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.one_half {
	&.last_column {
		@include breakpoint (600px) {
			width: 48%;
			margin-left: 2%;
		}
	}
}

.one_third {
	@include breakpoint (600px) {
		width: 31.3%;
		margin-left: 2%;
	}

	&:first-child {
		@include breakpoint (600px) {
			width: 33.3%;
			margin-left: 0;
		}
	}
}
