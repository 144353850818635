/**
 * Awards section
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.awards-section {
	background-color: $secondary;
}

.awards-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 35px 0;
	@include breakpoint (menu-break) {
		padding: 33.5px 0;
	}
}

.award {
	img {
		margin: 0;
		padding: 20px;
		max-width: 150px;
		@include breakpoint (menu-break) {
			margin: 40px;
			padding: 0;
			max-width: none;
		}
	}
}
