/**
 * Main Structure
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
body,
html {
	height: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	display: flex;
	flex-direction: column;
}

*,
*:after,
*:before {
	/* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}
/**
 * Wrapper
 */
.wrap {
	@include wrap;
}
/**
 * Main container
 */
#content {
	flex: 1 0 auto;
	padding-top: $header-height;
	@include breakpoint(menu-break) {
		padding-top: $header-height-desk;
	}
}
/**
 * Main content container
 */
[role="main"] {
	display: block;

	> article {
		width: 100%;
	}
}
/**
 * Figure & img fixes
 */
figure {
	margin: 0;
}

img {
	height: auto;
	/* Make sure images are scaled correctly. */
	max-width: 100%;
	/* Adhere to container width. */
	&[data-object-fit="cover"] {
		@include cover-image;
	}
}
/**
 * SVG
 */
svg {
	display: block;
	height: auto;
}

.svg {
	font-size: 0;
}
/**
 * UTILITY HELPER CLASSES
 */
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-secondary {
	color: $secondary !important;
}

.fade-in-target,
.fade-in-target-left,
.fade-in-target-right {
	opacity: 0;
}

.hide-on-medium-and-down {
	display: none;
	@include breakpoint (medium) {
		display: block;
	}
}

.hide-on-medium-and-up {
	display: block;
	@include breakpoint (medium) {
		display: none;
	}
}

.dispnone {
	display: none !important;
}

.opacity-full {
	opacity: 1 !important;
}

.z-depth {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.divider-dot {
	display: inline;
	position: relative;
	margin: 0 10px;

	&:after {
		content: '•';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $white;
	}
}
