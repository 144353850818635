/**
 * Pagination
 *
 * @since 1.0.0
 * @package leland-lifestyles
 */
// prev/next buttons

.nav-links {
	font-size: 0;

	.nav-next,
	.nav-previous {
		display: inline-block;

		&:nth-child(2) {
			margin-left: bsu(1/3);
		}
	}

	a {
		@include button;
	}
}
// numbered
.numbered-pagination {
	width: 100%;
	line-height: 1;

	.current,
	.dots,
	a,
	li,
	ul {
		display: flex;
		flex-flow: row nowrap;
	}

	.current,
	.dots,
	a,
	ul {
		justify-content: center;
	}

	li {
		margin: 0 rem-calc(5);
	}

	.current,
	.dots,
	a {
		align-items: center;
		min-width: rem-calc(44);
		color: $gray-dark;
	}

	.current,
	a {
		border: $border-width solid $gray;
	}

	.current,
	a:hover {
		border-color: $primary;
		color: $primary;
	}

	a {
		transition-property: color, border-color;
		@include transition-custom;
	}

	.dots,
	.next,
	.prev {
		padding-left: rem-calc(6);
		padding-right: rem-calc(6);
		min-width: 0;
	}

	.current,
	.dots {
		cursor: default;
	}

	.next,
	.prev {
		font-size: 0;
		color: $gray;

		&,
		&:hover {
			border-color: transparent;
		}

		.svg-inline--fa {
			font-size: $global-font-size;
		}
	}
}
