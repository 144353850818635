/**
 * Button
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Base button hover
 */
@mixin button-hover {
	background-color: $primary-lighter;
	border-color: $primary-lighter;
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);

	&:active {
		box-shadow: none;
	}
}
/**
 * Secondary hovered/focused button
 */
@mixin button-secondary-hover {
	background-color: $primary-light;
	border-color: $primary-light;
}
/**
 * Ghost button hover
 */
@mixin button-ghost-hover {
	color: $white;
	border-color: $primary;
	background-color: $primary;

	&.light {
		color: $primary;
		background-color: $white;
		border-color: $white;
	}
}
/**
 * Secondary button
 */
@mixin button-secondary {
	background-color: $primary;
	border-color: $primary;
	font-size: 14px;

	&:focus,
	&:hover {
		@include button-secondary-hover;
	}
}
/**
 * Light ghost button
 */
@mixin button-ghost-light {
	color: $white;
	border-color: $white;
}
/**
 * Ghost button
 */
@mixin button-ghost {
	color: $primary;
	background-color: rgba(255, 255, 255, 0.2);
	border-color: $primary;
	padding: 20px 25px;
	@include breakpoint (menu-break) {
		padding: 20px 30px;
	}

	&:focus,
	&:hover {
		@include button-ghost-hover;
	}

	&.light {
		@include button-ghost-light;
	}
}
/**
 * Base button
 */
@mixin button {
	@include flex-row(true, true);
	padding: rem-calc(15) rem-calc(25);
	text-align: center;
	font-family: $font-serif-bold;
	font-size: $global-font-size;
	background: $primary;
	border: $border-width solid $primary;
	outline: none;
	cursor: pointer;
	transition-property: background-color, color, box-shadow, border-color;
	@include transition-custom(0.2s, 0.3s, 0.2s, 0.2s);
	@include breakpoint (menu-break) {
		font-size: 21px;
	}

	&,
	&:visited {
		color: $white;
	}

	.icon {
		&:first-child {
			margin-left: rem-calc(-3);
			margin-right: rem-calc(10);
		}

		&:last-child {
			margin-left: rem-calc(10);
		}
	}

	&.light {
		background-color: $primary-light;
		border-color: $primary-light;
	}

	span {
		display: inline-block;
	}

	&:focus,
	&:hover {
		@include button-hover;
	}

	&.secondary {
		@include button-secondary;
	}

	&.ghost {
		@include button-ghost;
	}
}

[role="button"],
[type="submit"],
[type="button"]:not(.flickity-button):not(.flickity-prev-next-button),
button:not(.flickity-button):not(.flickity-prev-next-button) {
	@include button;
}
