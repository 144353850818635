/**
 * Footer
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
footer[role="contentinfo"] {
	position: relative;
	flex-shrink: 0;
	background: $footer-color;

	.footer-wrap {
		@include wrap(1800px);
		display: flex;
		flex-direction: column;
		align-items: center;
		@include breakpoint (menu-break) {
			flex-direction: row;
			justify-content: center;
		}
	}
}

.footer-logo {
	width: 100%;
	max-width: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 38px 0 30px;

	a {
		img {
			max-width: 120px;
			width: 120px;
			height: 100%;
			@include breakpoint (menu-break) {
				max-width: 210px;
				width: 210px;
			}
		}
	}
	@include breakpoint (menu-break) {
		width: 210px;
		max-width: 210px;
		position: absolute;
		left: 30px;
		top: 50%;
		transform: translateY(-50%);
		margin: 0;
	}
	@include breakpoint (vlarge) {
		left: 130px;
	}
}

.site-logo-footer {
	p {
		display: none;
	}
}

.footer-content {
	margin: 0;
	@include breakpoint (menu-break) {
		margin: 45px 0;
		padding-left: 210px;
	}
	@include breakpoint (xxlarge) {
		padding-left: 0;
	}

	.address {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0 0 35px;
		@include breakpoint (menu-break) {
			flex-direction: row;
			margin: 0;
		}

		a,
		p {
			font-family: $font-serif-regular;
			font-size: $global-font-size;
			color: $white;
			padding: 0 5px;
			margin: 0;
			@include breakpoint (xxlarge) {
				font-size: 18px;
			}
		}

		p {
			font-weight: 600;
		}

		address {
			max-width: 175px;
			text-align: center;
			@include breakpoint (menu-break) {
				max-width: none;
				text-align: unset;
			}
		}

		.divider-dot {
			display: none;
			@include breakpoint (xlarge) {
				display: inline-block;
			}
		}
	}

	.copyright {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		@include breakpoint (menu-break) {
			margin-top: 10px;
		}

		a,
		p {
			max-width: 225px;
			font-family: $font-serif-regular;
			font-size: $global-font-size;
			color: $footer-copyright;
			@include breakpoint (xxlarge) {
				font-size: 18px;
			}
		}

		p {
			margin: 0;
			@include breakpoint (menu-break) {
				margin-bottom: 0;
				max-width: none;
			}
		}

		.divider-dot {
			&:after {
				color: $footer-copyright;
			}
		}
	}
}
