/**
 * Banner
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.banner {
	height: 340px;
	position: relative;
	display: flex;
	overflow: hidden;
	align-items: center;
	@include breakpoint (menu-break) {
		height: 705px;
	}

	.wrap {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		img {
			width: 75px;
			height: 75px;
			margin: 31px 0;
			@include breakpoint (menu-break) {
				width: 165px;
				height: 165px;
				margin: 55px 0;
			}
		}
	}
	// heading
	h1 {
		color: $white;
		letter-spacing: -2.5px;
	}
	// subtitle
	h2 {
		font-family: $font-serif-italic;
		font-size: $global-font-size;
		color: $white;
		margin: 10px 0;
		@include breakpoint (menu-break) {
			font-size: 21px;
		}
	}

	a[role="button"] {
		margin-top: 25px;
		@include breakpoint (menu-break) {
			margin-top: 48px;
		}
	}
	// background image
	img {
		z-index: -1;
	}

	.scrollto-button {
		cursor: pointer;
		width: 65px;
		height: 65px;
		position: absolute;
		bottom: 0;
		background-color: $primary;
		display: flex;
		justify-content: center;
		align-items: center;
		@include breakpoint (menu-break) {
			width: 130px;
			height: 130px;
		}

		svg {
			font-size: 20px;
			color: $white;
			@include breakpoint (menu-break) {
				font-size: 35px;
			}
		}
	}
}

.heading-small {
	font-size: 30px;
	@include breakpoint (menu-break) {
		font-size: 68px;
	}
}

.large-banner {
	height: 430px;
	@include breakpoint (menu-break) {
		height: 970px;
	}
}
