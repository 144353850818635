/**
 * Service Single Page Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.page-template-service {
	#content {
		.banner {
			.wrap {
				justify-content: center;
				position: relative;

				.heading {
					h2 {
						@include breakpoint (menu-break) {
							font-size: 32px;
						}
					}
				}
			}
		}

		.service-single {
			.entry-content {
				h3 {
					margin-top: 18px;
				}

				blockquote {
					p {
						padding: 0 bsu(1.5);
						@include breakpoint (menu-break) {
							padding: bsu(1) bsu(1.5);
						}
					}
				}

				h4 {
					font-size: 18px;
					text-align: right;
					padding: 0 bsu(1.5) bsu(1);
					margin-top: 0;
					@include breakpoint (menu-break) {
						font-size: 26px;
					}
				}
			}
		}
	}
}
