/**
 * Quote Slider Section styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.quote-slider {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	margin: bsu(2) 0;
	@include breakpoint (menu-break) {
		height: 975px;
		@include wrap(1600px);
	}

	.quote-image {
		width: 281px;
		height: 176px;
		position: relative;
		@include breakpoint (small) {
			width: 425px;
		}
		@include breakpoint (medium) {
			width: 600px;
		}
		@include breakpoint (menu-break) {
			width: 900px;
			height: 600px;
			position: absolute;
			top: 0;
			left: 0;
		}
		@include breakpoint (menu-break) {
			width: 900px;
			height: 600px;
			position: absolute;
			top: 0;
			left: 0;
		}
		@include breakpoint (1600px) {
			width: 1170px;
			height: 756px;
		}
	}

	.quote {
		width: 281px;
		height: 305px;
		background-color: $primary;
		margin-top: 25px;
		@include breakpoint (small) {
			width: 425px;
			height: 425px;
		}
		@include breakpoint (medium) {
			width: 600px;
		}
		@include breakpoint (menu-break) {
			position: absolute;
			bottom: 0;
			right: 0;
		}
		@include breakpoint (1600px) {
			width: 720px;
		}
	}

	.background-image {
		img {
			object-fit: contain;
			position: absolute;
			top: 0;
			left: 60%;
			width: 40%;
			height: 80%;
			z-index: -3;
		}

		&::before {
			@include overlay(-2);
			background: rgba($white, 0.25);
		}
	}
}
