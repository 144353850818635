@charset "UTF-8";
/**
 * Main Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/**
 * Vendor Partials
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/**
 * Helper functions
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Base spacing unit
 */
/**
 * Create a "transparent" version of a color
 */
/**
 * Rem Calc!
 */
/**
 * Variables
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Primary & Secondary Colors
 */
/**
 * Neutral & Support Colors
 */
/**
 * Gradients
 */
/**
 * Fonts
 */
/**
 * Spacing
 */
/**
 * Misc heights/widths
 */
/**
 * Borders
 */
/**
 * Mixins
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Generic wrapper
 */
/**
 * Object-fit "cover"
 */
/**
 * Centered block
 */
/**
 * Centered element trick!
 */
/**
 * Make a flex "row"
 */
/**
 * Font smoothing
 */
/**
 * Kill font smoothing!
 */
/**
 * Inline text link
 */
/**
 * Styled list
 */
/**
 * Bulleted list
 *
 * Icons inserted with js/foot/list-icons.js
 */
/**
 * Numbered list
 */
/**
 * Remove bottom margin if last child
 */
/**
 * Remove top margin if first child
 */
/**
 * WYSIWYG content format
 */
/**
 * Responsive embed container
 */
/**
 * Fluid Property!
 */
/**
 * Overlay
 */
/**
 * Generic transition
 */
/**
 * Custom transition
 */
/**
 * Clearfix
 */
/*
	SCREEN READERS
*/
/**
 * Breakpoints
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Typography
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
*::selection {
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  text-shadow: none; }

*::-moz-selection {
  background: rgba(0, 0, 0, 0.9);
  color: #ffffff;
  text-shadow: none; }

body,
html {
  font-size: 16px; }

body {
  line-height: 1.6667;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #2b2f38;
  font-family: "Andada Bold", serif;
  font-weight: normal; }

h1 {
  font-size: 40px;
  line-height: 1.05; }
  @media (min-width: 1100px) {
    h1 {
      font-size: 134px;
      line-height: 1.01; } }

h2 {
  font-size: 30px;
  line-height: 1.15; }
  @media (min-width: 1100px) {
    h2 {
      font-size: 68px;
      line-height: 1.13; } }

h3 {
  font-size: 24px;
  line-height: 1.17; }
  @media (min-width: 1100px) {
    h3 {
      font-size: 54px;
      line-height: 1.3; } }

h4 {
  font-size: 21px;
  line-height: 1.14; }
  @media (min-width: 1100px) {
    h4 {
      font-size: 30px;
      line-height: 1.17; } }

/**
 * Paragraphs
 */
p {
  color: #6e6e6e;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  margin: 1.11113rem 0 0; }
  @media (min-width: 1100px) {
    p {
      font-size: 21px;
      line-height: 1.52; } }
  p > small,
  p .detail {
    color: #828c4e;
    line-height: 1.25;
    font-family: "Andada Italic", serif; }
  p a {
    color: #828c4e;
    border-bottom: 1px solid transparent;
    transition-property: color, border-bottom-color;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms; }
    p a:hover {
      color: #99a459;
      border-bottom-color: #99a459; }

/**
 * Anchors
 */
a,
a:visited {
  color: #828c4e;
  text-decoration: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  transition-property: color;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms; }
  a:hover:not([role="button"]),
  a:visited:hover:not([role="button"]) {
    color: #99a459; }

/**
 * Blockquotes & Cites
 */
blockquote,
q {
  color: #828c4e;
  font-family: "Andada Italic", serif;
  font-size: 18px;
  line-height: 1.67;
  margin: 0;
  font-style: italic; }
  @media (min-width: 1100px) {
    blockquote,
    q {
      font-size: 26px;
      line-height: 1.92; } }
  blockquote:after, blockquote:before,
  q:after,
  q:before {
    content: ""; }
  blockquote p,
  q p {
    color: #828c4e;
    font-family: "Andada Italic", serif;
    font-size: 18px;
    line-height: 1.67;
    margin: 1.11113rem 0 0;
    font-style: italic; }
    @media (min-width: 1100px) {
      blockquote p,
      q p {
        font-size: 26px;
        line-height: 1.92; } }
    blockquote p:after, blockquote p:before,
    q p:after,
    q p:before {
      content: ""; }

/**
 * Horizontal Rule
 */
hr {
  margin: 3.3334rem 0;
  border: none; }

/**
 * Address
 */
address {
  font-style: normal; }

/**
 * Lists
 */
ol,
ul {
  color: #6e6e6e;
  font-size: 14px;
  font-weight: 300;
  line-height: 2.14;
  margin: 0;
  padding: 0;
  list-style-type: none; }
  @media (min-width: 1100px) {
    ol,
    ul {
      font-size: 18px;
      line-height: 2.22; } }

ol.numbered li,
ul.bulleted li {
  position: relative;
  margin: 0.83335rem 0;
  padding-left: 1.6667rem; }
  ol.numbered li:after, ol.numbered li:before,
  ol.numbered li .svg-inline--fa,
  ul.bulleted li:after,
  ul.bulleted li:before,
  ul.bulleted li .svg-inline--fa {
    color: #828c4e;
    position: absolute;
    left: 0; }

ol.numbered ol,
ol.numbered ul,
ul.bulleted ol,
ul.bulleted ul {
  margin-top: 0; }

ol.numbered a,
ul.bulleted a {
  color: #828c4e;
  border-bottom: 1px solid transparent;
  transition-property: color, border-bottom-color;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms; }
  ol.numbered a:hover,
  ul.bulleted a:hover {
    color: #99a459;
    border-bottom-color: #99a459; }

ul.bulleted li:after, ul.bulleted li:before,
ul.bulleted li .svg-inline--fa {
  top: 9px; }

ol.numbered,
ol.numbered ol {
  counter-reset: section; }

ol.numbered li:before {
  counter-increment: section;
  content: counters(section, ".") "."; }

ol.numbered ol li:before {
  font-size: 0.9rem; }

/**
 * WYSIWYG content output
 */
.entry-content > ul,
.entry-content > ol,
.entry-content blockquote,
.entry-content form,
.entry-content h2,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  margin-top: 1.6667rem; }

.entry-content [role="button"] {
  margin: 1.6667rem 0.83335rem 0 0; }

.entry-content > h3:last-child,
.entry-content > h4:last-child,
.entry-content > h5:last-child,
.entry-content > h6:last-child,
.entry-content > ol:last-child,
.entry-content > p:last-child,
.entry-content > ul:last-child,
.entry-content > h2:last-child,
.entry-content blockquote:last-child,
.entry-content blockquote p:last-child,
.entry-content h1:last-child {
  margin-bottom: 0; }

.entry-content > h3:first-child:not(p),
.entry-content > h4:first-child:not(p),
.entry-content > h5:first-child:not(p),
.entry-content > h6:first-child:not(p),
.entry-content > ol:first-child:not(p),
.entry-content > p:first-child:not(p),
.entry-content > ul:first-child:not(p),
.entry-content > h2:first-child:not(p),
.entry-content blockquote:first-child:not(p),
.entry-content blockquote p:first-child:not(p),
.entry-content h1:first-child:not(p) {
  margin-top: 0; }

.entry-content > ul li,
.entry-content > ol li {
  position: relative;
  margin: 0.83335rem 0;
  padding-left: 1.6667rem; }
  .entry-content > ul li:after, .entry-content > ul li:before,
  .entry-content > ul li .svg-inline--fa,
  .entry-content > ol li:after,
  .entry-content > ol li:before,
  .entry-content > ol li .svg-inline--fa {
    color: #828c4e;
    position: absolute;
    left: 0; }

.entry-content > ul ol,
.entry-content > ul ul,
.entry-content > ol ol,
.entry-content > ol ul {
  margin-top: 0; }

.entry-content > ul a,
.entry-content > ol a {
  color: #828c4e;
  border-bottom: 1px solid transparent;
  transition-property: color, border-bottom-color;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms; }
  .entry-content > ul a:hover,
  .entry-content > ol a:hover {
    color: #99a459;
    border-bottom-color: #99a459; }

.entry-content > ul li:after, .entry-content > ul li:before,
.entry-content > ul li .svg-inline--fa {
  top: 9px; }

.entry-content > ol,
.entry-content > ol ol {
  counter-reset: section; }

.entry-content > ol li:before {
  counter-increment: section;
  content: counters(section, ".") "."; }

.entry-content > ol ol li:before {
  font-size: 0.9rem; }

.entry-content:first-child {
  margin-top: 44px; }
  @media (min-width: 1100px) {
    .entry-content:first-child {
      margin-top: 114px; } }

.entry-content:last-child {
  margin-bottom: 44px; }
  @media (min-width: 1100px) {
    .entry-content:last-child {
      margin-bottom: 114px; } }

/**
* Font face
*/
@font-face {
  font-family: 'Andada Regular';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/andada-regular.otf") format("opentype"), url("../fonts/andada-regular.woff") format("woff"); }

@font-face {
  font-family: 'Andada Bold';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/andada-bold.otf") format("opentype"), url("../fonts/andada-bold.woff") format("woff"); }

@font-face {
  font-family: 'Andada Italic';
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/andada-italic.otf") format("opentype"), url("../fonts/andada-italic.ttf") format("truetype"), url("../fonts/andada-italic.woff") format("woff"); }

/**
 * Main Structure
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
body,
html {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body {
  display: flex;
  flex-direction: column; }

*,
*:after,
*:before {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

/**
 * Wrapper
 */
.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1200px; }

/**
 * Main container
 */
#content {
  flex: 1 0 auto;
  padding-top: 70px; }
  @media (min-width: 1100px) {
    #content {
      padding-top: 100px; } }

/**
 * Main content container
 */
[role="main"] {
  display: block; }
  [role="main"] > article {
    width: 100%; }

/**
 * Figure & img fixes
 */
figure {
  margin: 0; }

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */ }
  img[data-object-fit="cover"] {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/**
 * SVG
 */
svg {
  display: block;
  height: auto; }

.svg {
  font-size: 0; }

/**
 * UTILITY HELPER CLASSES
 */
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-secondary {
  color: #2b2f38 !important; }

.fade-in-target,
.fade-in-target-left,
.fade-in-target-right {
  opacity: 0; }

.hide-on-medium-and-down {
  display: none; }
  @media (min-width: 640px) {
    .hide-on-medium-and-down {
      display: block; } }

.hide-on-medium-and-up {
  display: block; }
  @media (min-width: 640px) {
    .hide-on-medium-and-up {
      display: none; } }

.dispnone {
  display: none !important; }

.opacity-full {
  opacity: 1 !important; }

.z-depth {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

.divider-dot {
  display: inline;
  position: relative;
  margin: 0 10px; }
  .divider-dot:after {
    content: '•';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff; }

/**
 * Component Partials
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Responsive Embed
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 440px;
  overflow: hidden; }
  .embed-container embed,
  .embed-container iframe,
  .embed-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/**
 * Button
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Base button hover
 */
/**
 * Secondary hovered/focused button
 */
/**
 * Ghost button hover
 */
/**
 * Secondary button
 */
/**
 * Light ghost button
 */
/**
 * Ghost button
 */
/**
 * Base button
 */
[role="button"],
[type="submit"],
[type="button"]:not(.flickity-button):not(.flickity-prev-next-button),
button:not(.flickity-button):not(.flickity-prev-next-button) {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.9375rem 1.5625rem;
  text-align: center;
  font-family: "Andada Bold", serif;
  font-size: 16px;
  background: #828c4e;
  border: 4px solid #828c4e;
  outline: none;
  cursor: pointer;
  transition-property: background-color, color, box-shadow, border-color;
  transition-timing-function: cubic-bezier(0.53, 0.86, 0.54, 0.99);
  transition-duration: 0.2s, 0.3s, 0.2s, 0.2s; }
  @media (min-width: 1100px) {
    [role="button"],
    [type="submit"],
    [type="button"]:not(.flickity-button):not(.flickity-prev-next-button),
    button:not(.flickity-button):not(.flickity-prev-next-button) {
      font-size: 21px; } }
  [role="button"], [role="button"]:visited,
  [type="submit"],
  [type="submit"]:visited,
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button),
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button):visited,
  button:not(.flickity-button):not(.flickity-prev-next-button),
  button:not(.flickity-button):not(.flickity-prev-next-button):visited {
    color: #ffffff; }
  [role="button"] .icon:first-child,
  [type="submit"] .icon:first-child,
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button) .icon:first-child,
  button:not(.flickity-button):not(.flickity-prev-next-button) .icon:first-child {
    margin-left: -0.1875rem;
    margin-right: 0.625rem; }
  [role="button"] .icon:last-child,
  [type="submit"] .icon:last-child,
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button) .icon:last-child,
  button:not(.flickity-button):not(.flickity-prev-next-button) .icon:last-child {
    margin-left: 0.625rem; }
  [role="button"].light,
  [type="submit"].light,
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).light,
  button:not(.flickity-button):not(.flickity-prev-next-button).light {
    background-color: #87914e;
    border-color: #87914e; }
  [role="button"] span,
  [type="submit"] span,
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button) span,
  button:not(.flickity-button):not(.flickity-prev-next-button) span {
    display: inline-block; }
  [role="button"]:focus, [role="button"]:hover,
  [type="submit"]:focus,
  [type="submit"]:hover,
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button):focus,
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button):hover,
  button:not(.flickity-button):not(.flickity-prev-next-button):focus,
  button:not(.flickity-button):not(.flickity-prev-next-button):hover {
    background-color: #99a459;
    border-color: #99a459;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3); }
    [role="button"]:focus:active, [role="button"]:hover:active,
    [type="submit"]:focus:active,
    [type="submit"]:hover:active,
    [type="button"]:not(.flickity-button):not(.flickity-prev-next-button):focus:active,
    [type="button"]:not(.flickity-button):not(.flickity-prev-next-button):hover:active,
    button:not(.flickity-button):not(.flickity-prev-next-button):focus:active,
    button:not(.flickity-button):not(.flickity-prev-next-button):hover:active {
      box-shadow: none; }
  [role="button"].secondary,
  [type="submit"].secondary,
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).secondary,
  button:not(.flickity-button):not(.flickity-prev-next-button).secondary {
    background-color: #828c4e;
    border-color: #828c4e;
    font-size: 14px; }
    [role="button"].secondary:focus, [role="button"].secondary:hover,
    [type="submit"].secondary:focus,
    [type="submit"].secondary:hover,
    [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).secondary:focus,
    [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).secondary:hover,
    button:not(.flickity-button):not(.flickity-prev-next-button).secondary:focus,
    button:not(.flickity-button):not(.flickity-prev-next-button).secondary:hover {
      background-color: #87914e;
      border-color: #87914e; }
  [role="button"].ghost,
  [type="submit"].ghost,
  [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).ghost,
  button:not(.flickity-button):not(.flickity-prev-next-button).ghost {
    color: #828c4e;
    background-color: rgba(255, 255, 255, 0.2);
    border-color: #828c4e;
    padding: 20px 25px; }
    @media (min-width: 1100px) {
      [role="button"].ghost,
      [type="submit"].ghost,
      [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).ghost,
      button:not(.flickity-button):not(.flickity-prev-next-button).ghost {
        padding: 20px 30px; } }
    [role="button"].ghost:focus, [role="button"].ghost:hover,
    [type="submit"].ghost:focus,
    [type="submit"].ghost:hover,
    [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).ghost:focus,
    [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).ghost:hover,
    button:not(.flickity-button):not(.flickity-prev-next-button).ghost:focus,
    button:not(.flickity-button):not(.flickity-prev-next-button).ghost:hover {
      color: #ffffff;
      border-color: #828c4e;
      background-color: #828c4e; }
      [role="button"].ghost:focus.light, [role="button"].ghost:hover.light,
      [type="submit"].ghost:focus.light,
      [type="submit"].ghost:hover.light,
      [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).ghost:focus.light,
      [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).ghost:hover.light,
      button:not(.flickity-button):not(.flickity-prev-next-button).ghost:focus.light,
      button:not(.flickity-button):not(.flickity-prev-next-button).ghost:hover.light {
        color: #828c4e;
        background-color: #ffffff;
        border-color: #ffffff; }
    [role="button"].ghost.light,
    [type="submit"].ghost.light,
    [type="button"]:not(.flickity-button):not(.flickity-prev-next-button).ghost.light,
    button:not(.flickity-button):not(.flickity-prev-next-button).ghost.light {
      color: #ffffff;
      border-color: #ffffff; }

/**
 * Datepicker UI theming
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.ui-datepicker {
  background: #ffffff;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.15); }

html.pum-open.pum-open-overlay.pum-open-scrollable body > [aria-hidden].ui-datepicker {
  padding-right: 0; }

.ui-datepicker-calendar thead,
.ui-datepicker-header {
  color: #ffffff; }

.ui-datepicker-calendar,
.ui-datepicker-header {
  border-bottom: 4px solid #000000; }

.ui-datepicker-header {
  position: relative;
  border-top: 4px solid #000000;
  background: #000000;
  text-align: center; }
  .ui-datepicker-header select {
    margin: 0 0.41668rem;
    padding: 0.41668rem 1.25rem 0.41668rem 0.83335rem;
    width: auto;
    background-image: url("../images/chevron-down-white.svg");
    background-color: transparent;
    background-position: right;
    background-size: 0.75rem;
    border: none;
    text-transform: uppercase;
    cursor: pointer; }
  .ui-datepicker-header option {
    color: #000000; }

.ui-datepicker-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center; }

.ui-datepicker-next,
.ui-datepicker-prev {
  position: absolute;
  top: 0;
  width: 1.25rem;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  font-size: 0;
  cursor: pointer; }

.ui-datepicker-prev {
  left: 0.83335rem;
  background-image: url("../images/chevron-square-left.svg"); }

.ui-datepicker-next {
  right: 0.83335rem;
  background-image: url("../images/chevron-square-right.svg"); }

.ui-datepicker-buttonpane {
  padding: 0.41668rem;
  background: #828c4e; }
  .ui-datepicker-buttonpane button {
    margin-right: 0.41668rem;
    padding: 0.3125rem 1rem;
    background: #2b2f38; }

.ui-datepicker-calendar {
  font-size: 0.875rem; }
  .ui-datepicker-calendar thead {
    background: #828c4e;
    border: none; }
  .ui-datepicker-calendar td a,
  .ui-datepicker-calendar th {
    text-align: center;
    padding: 0.375rem 0.625rem; }
  .ui-datepicker-calendar td a {
    display: block;
    transition-property: background-color;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms; }
    .ui-datepicker-calendar td a.ui-state-active, .ui-datepicker-calendar td a:hover {
      color: #ffffff; }
    .ui-datepicker-calendar td a:hover {
      background: #000000; }
    .ui-datepicker-calendar td a.ui-state-active {
      background: #828c4e;
      font-weight: 700; }
  .ui-datepicker-calendar tr:nth-child(even) {
    background-color: #eaeaea; }
  .ui-datepicker-calendar a {
    color: #000000; }

/**
 * Form
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
form ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

input,
label,
option,
select,
textarea {
  display: block;
  width: 100%;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #9b9b9b; }
  @media (min-width: 1100px) {
    input,
    label,
    option,
    select,
    textarea {
      font-size: 16px; } }
  input:focus,
  label:focus,
  option:focus,
  select:focus,
  textarea:focus {
    outline: none; }
  input::placeholder,
  label::placeholder,
  option::placeholder,
  select::placeholder,
  textarea::placeholder {
    color: #9b9b9b; }

input,
select,
textarea {
  line-height: 1.6667;
  border: 3px solid #bfbfbf;
  padding-left: 25px;
  margin-bottom: 15px;
  padding: 0.65625rem 30px;
  background: #ffffff;
  border-radius: 0;
  transition-property: border-color;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms; }
  @media (min-width: 1100px) {
    input,
    select,
    textarea {
      padding: 0.875rem 30px;
      margin-bottom: 30px; } }
  input:focus,
  select:focus,
  textarea:focus {
    border-color: #9b9b9b; }

textarea {
  resize: vertical;
  height: 140px; }

[type="search"] {
  -webkit-appearance: textfield; }

select {
  appearance: none; }

select {
  padding-right: 52px;
  background-image: url("../images/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  background-size: 16px; }
  select::-ms-expand {
    display: none; }

label {
  margin-bottom: 0.41668rem;
  padding-left: 0.875rem;
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  label:focus {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #000000;
    display: block;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

/**
 * Checkboxes/radios
 */
[type="checkbox"],
[type="radio"] {
  position: absolute;
  width: 4px;
  height: 4px;
  opacity: 0; }

[type="checkbox"] + label,
[type="radio"] + label,
.woocommerce-form__label-for-checkbox {
  position: relative;
  margin: 0 0 0 0.625rem;
  padding: 0.375rem 0 0.375rem 2.5rem;
  width: auto;
  cursor: pointer;
  transition-property: color; }
  .gfield_checkbox [type="checkbox"] + label,
  .gfield_radio [type="checkbox"] + label, .gfield_checkbox
  [type="radio"] + label,
  .gfield_radio
  [type="radio"] + label, .gfield_checkbox
  .woocommerce-form__label-for-checkbox,
  .gfield_radio
  .woocommerce-form__label-for-checkbox {
    margin-left: 0.5rem; }
  [type="checkbox"] + label, [type="checkbox"] + label:after, [type="checkbox"] + label:before,
  [type="radio"] + label,
  [type="radio"] + label:after,
  [type="radio"] + label:before,
  .woocommerce-form__label-for-checkbox,
  .woocommerce-form__label-for-checkbox:after,
  .woocommerce-form__label-for-checkbox:before {
    transition-timing-function: ease-in-out;
    transition-duration: 200ms; }
  [type="checkbox"] + label:after, [type="checkbox"] + label:before,
  [type="radio"] + label:after,
  [type="radio"] + label:before,
  .woocommerce-form__label-for-checkbox:after,
  .woocommerce-form__label-for-checkbox:before {
    content: '';
    position: absolute; }
  [type="checkbox"] + label:before,
  [type="radio"] + label:before,
  .woocommerce-form__label-for-checkbox:before {
    top: 0.3125rem;
    left: 0;
    margin-right: 0.875rem;
    width: 25px;
    height: 25px;
    border: 4px solid #6e6e6e;
    transition-property: border-color; }
  [type="checkbox"] + label:after,
  [type="radio"] + label:after,
  .woocommerce-form__label-for-checkbox:after {
    top: 12px;
    left: 7px;
    width: 11px;
    height: 11px;
    transform: scale(0);
    transition-property: background-color, transform; }
  [type="checkbox"] + label:hover,
  [type="radio"] + label:hover,
  .woocommerce-form__label-for-checkbox:hover {
    color: #828c4e; }

[type="checkbox"]:focus + label:before,
[type="radio"]:focus + label:before {
  border-color: #828c4e; }

[type="checkbox"]:checked + label:after,
[type="radio"]:checked + label:after,
.woocommerce-form__label-for-checkbox.input-checked:after {
  transform: scale(1);
  background: #828c4e; }

[type="radio"] + label:before,
[type="radio"] + label:after {
  border-radius: 9999px; }

.fields > li ul,
.fields > li ul,
ul.gfield_checkbox,
ul.gfield_radio {
  margin: 0.41668rem 0 1.11113rem; }

.ginput_container_date {
  position: relative; }
  .ginput_container_date .svg-inline--fa {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    font-size: 1.25rem;
    transform: translateY(-50%);
    color: #6e6e6e; }

[type="submit"] {
  margin-top: 0.55557rem; }

/**
 * Validation
 */
.validation-message,
.validation_error,
.validation_message {
  color: #fa0f48;
  font-size: 16px; }

.validation_error {
  margin-bottom: 1.6667rem;
  padding: 0.9375rem 1.25rem;
  text-align: center;
  border: 4px solid #fa0f48; }

.validation-message,
.validation_message {
  margin-top: -0.55557rem;
  margin-bottom: 1.25002rem;
  padding-left: 0.8125rem; }
  .gfield_checkbox .validation-message,
  .gfield_radio .validation-message, .gfield_checkbox
  .validation_message,
  .gfield_radio
  .validation_message {
    padding-left: 0.75rem; }
  .validation-message .svg-inline--fa,
  .validation_message .svg-inline--fa {
    margin-right: 0.4375rem; }
  @media (min-width: 640px) {
    .validation-message,
    .validation_message {
      margin-top: -0.83335rem; } }

.fields > li.error input,
.fields > li.error select,
.fields > li.error textarea,
.gfield_error input,
.gfield_error select,
.gfield_error textarea {
  border-color: #fa0f48; }

/**
 * Layout
 */
.fields,
.gform_fields {
  font-size: 0;
  margin: 0 -0.83335rem; }

.fields > li,
.gfield {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 15px;
  width: 100%; }
  @media (min-width: 640px) {
    .fields > li.one-half,
    .gfield.one-half {
      width: 50%; } }

/**
 * Pagination
 *
 * @since 1.0.0
 * @package leland-lifestyles
 */
.nav-links {
  font-size: 0; }
  .nav-links .nav-next,
  .nav-links .nav-previous {
    display: inline-block; }
    .nav-links .nav-next:nth-child(2),
    .nav-links .nav-previous:nth-child(2) {
      margin-left: 0.55557rem; }
  .nav-links a {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0.9375rem 1.5625rem;
    text-align: center;
    font-family: "Andada Bold", serif;
    font-size: 16px;
    background: #828c4e;
    border: 4px solid #828c4e;
    outline: none;
    cursor: pointer;
    transition-property: background-color, color, box-shadow, border-color;
    transition-timing-function: cubic-bezier(0.53, 0.86, 0.54, 0.99);
    transition-duration: 0.2s, 0.3s, 0.2s, 0.2s; }
    @media (min-width: 1100px) {
      .nav-links a {
        font-size: 21px; } }
    .nav-links a, .nav-links a:visited {
      color: #ffffff; }
    .nav-links a .icon:first-child {
      margin-left: -0.1875rem;
      margin-right: 0.625rem; }
    .nav-links a .icon:last-child {
      margin-left: 0.625rem; }
    .nav-links a.light {
      background-color: #87914e;
      border-color: #87914e; }
    .nav-links a span {
      display: inline-block; }
    .nav-links a:focus, .nav-links a:hover {
      background-color: #99a459;
      border-color: #99a459;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3); }
      .nav-links a:focus:active, .nav-links a:hover:active {
        box-shadow: none; }
    .nav-links a.secondary {
      background-color: #828c4e;
      border-color: #828c4e;
      font-size: 14px; }
      .nav-links a.secondary:focus, .nav-links a.secondary:hover {
        background-color: #87914e;
        border-color: #87914e; }
    .nav-links a.ghost {
      color: #828c4e;
      background-color: rgba(255, 255, 255, 0.2);
      border-color: #828c4e;
      padding: 20px 25px; }
      @media (min-width: 1100px) {
        .nav-links a.ghost {
          padding: 20px 30px; } }
      .nav-links a.ghost:focus, .nav-links a.ghost:hover {
        color: #ffffff;
        border-color: #828c4e;
        background-color: #828c4e; }
        .nav-links a.ghost:focus.light, .nav-links a.ghost:hover.light {
          color: #828c4e;
          background-color: #ffffff;
          border-color: #ffffff; }
      .nav-links a.ghost.light {
        color: #ffffff;
        border-color: #ffffff; }

.numbered-pagination {
  width: 100%;
  line-height: 1; }
  .numbered-pagination .current,
  .numbered-pagination .dots,
  .numbered-pagination a,
  .numbered-pagination li,
  .numbered-pagination ul {
    display: flex;
    flex-flow: row nowrap; }
  .numbered-pagination .current,
  .numbered-pagination .dots,
  .numbered-pagination a,
  .numbered-pagination ul {
    justify-content: center; }
  .numbered-pagination li {
    margin: 0 0.3125rem; }
  .numbered-pagination .current,
  .numbered-pagination .dots,
  .numbered-pagination a {
    align-items: center;
    min-width: 2.75rem;
    color: #2d2d2d; }
  .numbered-pagination .current,
  .numbered-pagination a {
    border: 4px solid #6e6e6e; }
  .numbered-pagination .current,
  .numbered-pagination a:hover {
    border-color: #828c4e;
    color: #828c4e; }
  .numbered-pagination a {
    transition-property: color, border-color;
    transition-timing-function: cubic-bezier(0.53, 0.86, 0.54, 0.99);
    transition-duration: 200ms, 200ms, 200ms, 200ms; }
  .numbered-pagination .dots,
  .numbered-pagination .next,
  .numbered-pagination .prev {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    min-width: 0; }
  .numbered-pagination .current,
  .numbered-pagination .dots {
    cursor: default; }
  .numbered-pagination .next,
  .numbered-pagination .prev {
    font-size: 0;
    color: #6e6e6e; }
    .numbered-pagination .next, .numbered-pagination .next:hover,
    .numbered-pagination .prev,
    .numbered-pagination .prev:hover {
      border-color: transparent; }
    .numbered-pagination .next .svg-inline--fa,
    .numbered-pagination .prev .svg-inline--fa {
      font-size: 16px; }

/**
 * Breadcrumb (via Yoast)
 *
 * @since 1.0.0
 */
.breadcrumb .breadcrumb_last,
.breadcrumb a,
.breadcrumb span {
  display: inline-block;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 300; }

.breadcrumb .breadcrumb_last,
.breadcrumb a {
  padding-left: 0.55557rem;
  padding-right: 0.55557rem;
  color: #2d2d2d; }

.breadcrumb a {
  transition-property: color;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms; }
  .breadcrumb a:hover {
    color: #828c4e; }

.breadcrumb .breadcrumb_last {
  cursor: default; }

.breadcrumb .svg-inline--fa {
  color: #6e6e6e; }

/**
 * Default WordPress Stuff
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/*
	CLEARINGS
*/
.clear:before,
.comment-content:before,
.entry-content:before,
.site-footer:before,
.site-header:before {
  content: " ";
  display: table; }

.clear:after,
.comment-content:after,
.entry-content:after,
.site-footer:after,
.site-header:after {
  clear: both;
  content: " ";
  display: table; }

/*
	ALIGNMENTS
*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 3.3334rem;
  margin-bottom: 1.6667rem; }

.alignright {
  display: inline;
  float: right;
  margin-left: 3.3334rem;
  margin-bottom: 1.6667rem; }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

/*
	CAPTIONS / MEDIA
*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%; }
  .wp-caption img[class*="wp-image-"] {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .wp-caption .wp-caption-text {
    margin: 0.8075em 0; }

.wp-caption-text {
  text-align: center; }

.comment-content .wp-smiley,
.entry-content .wp-smiley,
.page-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0; }

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%; }

/*
	SCREEN READERS
*/
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  .screen-reader-text:focus {
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #000000;
    display: block;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0; }

/*
	JETPACK
*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none; }

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block; }

/**
 * Social icon navigation
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.socials {
  font-size: 0;
  margin: 0 -0.55557rem; }
  .socials a {
    display: inline-block;
    padding: 0.55557rem; }
  .socials .svg-inline--fa {
    font-size: 16px; }

/**
 * Page Loader Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.page-loader {
  width: 100%;
  height: 100%;
  background-color: #828c4e;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100; }

/**
 * Slider Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.slider {
  height: 182px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 300px; }
  @media (min-width: 640px) {
    .slider {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      max-width: 600px;
      height: 474px; } }
  @media (min-width: 1100px) {
    .slider {
      width: 780px;
      height: 474px; } }
  .slider .carousel-cell {
    width: 100%;
    height: 182px; }
    @media (min-width: 640px) {
      .slider .carousel-cell {
        width: 600px;
        height: 474px; } }
  .slider .flickity-prev-next-button {
    cursor: pointer;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #828c4e;
    border-radius: 0;
    padding: 0 5px; }
    @media (min-width: 640px) {
      .slider .flickity-prev-next-button {
        width: 50px;
        height: 110px; } }
    .slider .flickity-prev-next-button.previous {
      left: -14px; }
      @media (min-width: 640px) {
        .slider .flickity-prev-next-button.previous {
          left: -24px; } }
    .slider .flickity-prev-next-button.next {
      right: -14px; }
      @media (min-width: 640px) {
        .slider .flickity-prev-next-button.next {
          right: -24px; } }
    .slider .flickity-prev-next-button .flickity-button-icon {
      fill: #ffffff; }
    .slider .flickity-prev-next-button svg {
      width: 12px;
      height: 25px; }
      @media (min-width: 640px) {
        .slider .flickity-prev-next-button svg {
          width: 25px;
          height: 67px; } }

/**
 * Slider Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.text-slider {
  width: 100%;
  height: 100%;
  position: relative; }
  .text-slider .main-carousel {
    height: 100%; }
  .text-slider .carousel-cell {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px; }
    @media (min-width: 640px) {
      .text-slider .carousel-cell {
        padding: 50px; } }
    .text-slider .carousel-cell blockquote,
    .text-slider .carousel-cell p {
      color: #ffffff;
      font-size: 18px; }
      @media (min-width: 640px) {
        .text-slider .carousel-cell blockquote,
        .text-slider .carousel-cell p {
          font-size: 21px; } }
      @media (min-width: 1600px) {
        .text-slider .carousel-cell blockquote,
        .text-slider .carousel-cell p {
          font-size: 26px; } }
    .text-slider .carousel-cell p {
      font-family: "Andada Bold", serif;
      font-weight: 600; }
  .text-slider .flickity-prev-next-button {
    cursor: pointer;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #2b2f38;
    border-radius: 0;
    padding: 0 5px; }
    @media (min-width: 640px) {
      .text-slider .flickity-prev-next-button {
        width: 50px;
        height: 110px; } }
    .text-slider .flickity-prev-next-button.previous {
      left: -14px; }
      @media (min-width: 640px) {
        .text-slider .flickity-prev-next-button.previous {
          left: -24px; } }
    .text-slider .flickity-prev-next-button.next {
      right: -14px; }
      @media (min-width: 640px) {
        .text-slider .flickity-prev-next-button.next {
          right: -24px; } }
    .text-slider .flickity-prev-next-button .flickity-button-icon {
      fill: #ffffff; }
    .text-slider .flickity-prev-next-button svg {
      width: 12px;
      height: 25px; }
      @media (min-width: 640px) {
        .text-slider .flickity-prev-next-button svg {
          width: 25px;
          height: 67px; } }

/**
 * Layout Partials
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Header
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
header[role="banner"] {
  position: fixed;
  z-index: 50;
  top: 0;
  right: 0;
  left: 0;
  transition-property: box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.53, 0.86, 0.54, 0.99);
  /**
	 * Admin bar fix - for "non-static" headers
	 */ }
  header[role="banner"] .logo-toggle,
  header[role="banner"] .site-logo {
    display: flex;
    flex-flow: row nowrap; }
  header[role="banner"] .logo-toggle {
    flex: 1 0 auto;
    position: relative;
    z-index: 1;
    padding-left: 0.55557rem;
    height: 70px;
    background: #ffffff; }
  header[role="banner"] .site-logo {
    align-items: center;
    margin-right: auto; }
    header[role="banner"] .site-logo img,
    header[role="banner"] .site-logo svg {
      max-height: 35px; }
      @media (min-width: 640px) {
        header[role="banner"] .site-logo img,
        header[role="banner"] .site-logo svg {
          max-height: 50px; } }
      @media (min-width: 1100px) {
        header[role="banner"] .site-logo img,
        header[role="banner"] .site-logo svg {
          max-height: 82px; } }
    header[role="banner"] .site-logo p {
      display: none; }
  header[role="banner"] .logo {
    width: 120px; }
    @media (min-width: 640px) {
      header[role="banner"] .logo {
        width: 160px; } }
    @media (min-width: 1100px) {
      header[role="banner"] .logo {
        width: 249px; } }
  header[role="banner"] .nav-cta {
    display: none;
    align-items: center;
    background: #ffffff;
    margin: 0 0 0 20px; }
    header[role="banner"] .nav-cta [role="button"] {
      padding: 5px 30px; }
  @media (min-width: 1100px) {
    header[role="banner"] {
      display: flex;
      flex-flow: row nowrap;
      height: 100px;
      background: #ffffff;
      padding: 0 30px; }
      header[role="banner"] .logo-toggle {
        flex: 0 1 auto;
        margin-right: auto;
        padding: 0;
        height: auto;
        background: none; }
      header[role="banner"] .site-logo svg {
        max-height: 80px; }
      header[role="banner"] .svg {
        width: 250px; }
      header[role="banner"] .nav-cta {
        display: flex; }
        header[role="banner"] .nav-cta [role="button"] {
          font-size: 16px;
          letter-spacing: 0.5px; } }
  @media (min-width: 1716px) {
    header[role="banner"] {
      padding: 0 130px; }
      header[role="banner"] .nav-cta {
        display: flex; }
        header[role="banner"] .nav-cta [role="button"] {
          font-size: 21px; } }
  @media (min-width: 600px) {
    body.admin-bar header[role="banner"] {
      top: 46px !important; } }
  @media (min-width: 782px) {
    body.admin-bar header[role="banner"] {
      top: 32px !important; } }

/**
 * Mobile navigation toggle
 */
#menu-toggle {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center; }
  #menu-toggle svg {
    width: 35px;
    height: 35px;
    color: #828c4e; }
  @media (min-width: 1100px) {
    #menu-toggle {
      display: none; } }

/**
 * Hamburger Menu
 */
.hamburger-wrapper {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .hamburger-wrapper div {
    width: 45%;
    height: 2px;
    background-color: #828c4e;
    position: absolute; }
  .hamburger-wrapper .top {
    top: 35%; }
  .hamburger-wrapper .middle {
    top: 50%; }
  .hamburger-wrapper .bottom {
    top: 65%; }

/**
 * Opened navigation overlay
 */
.nav-overlay {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  position: fixed;
  cursor: pointer;
  background: #828c4e;
  opacity: 0.9; }
  @media (min-width: 1100px) {
    .nav-overlay {
      display: none !important; } }

/**
 * Navigation
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
header[role="banner"] nav {
  position: fixed;
  top: 70px;
  right: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  transform: translateY(-100%) translateY(-1px);
  transition-property: transform, top;
  transition-timing-function: cubic-bezier(0.53, 0.86, 0.54, 0.99);
  transition-duration: 400ms, 200ms, 200ms, 200ms;
  /**
	 * Main Navigation - Desktop "menu break"
	 */ }
  header[role="banner"] nav,
  header[role="banner"] nav .toggle-sub,
  header[role="banner"] nav a {
    display: block; }
  header[role="banner"] nav.toggled {
    transform: translateY(0%); }
  header[role="banner"] nav.scroll-y {
    bottom: 100%;
    overflow-y: scroll; }
    header[role="banner"] nav.scroll-y.toggled {
      bottom: 0; }
  header[role="banner"] nav ul {
    margin: 0;
    padding: 0;
    width: 100%; }
  header[role="banner"] nav li {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    padding-left: 0.55557rem; }
  header[role="banner"] nav .menu-item-has-children > a {
    width: calc(100% - 50px); }
  header[role="banner"] nav .sub-toggled > .toggle-sub {
    transform: rotateX(180deg); }
  header[role="banner"] nav a {
    flex: 1 0 auto;
    padding: 0.41668rem 0.83335rem;
    width: 100%;
    text-decoration: none;
    color: #2b2f38;
    font-family: "Andada Bold", serif; }
  header[role="banner"] nav .toggle-sub {
    flex: 0 1 auto;
    width: 40px;
    margin-left: 10px;
    padding: 0; }
  header[role="banner"] nav .sub-menu {
    order: 1;
    display: none; }
    header[role="banner"] nav .sub-menu a {
      color: #6e6e6e; }
  @media (min-width: 1100px) {
    header[role="banner"] nav {
      flex: 0 0 auto;
      display: flex;
      position: static;
      width: auto;
      transform: none;
      overflow: visible; }
      header[role="banner"] nav ul {
        display: flex;
        flex-flow: row nowrap; }
      header[role="banner"] nav li:hover > .toggle-sub {
        transform: translateY(-50%) rotateX(180deg); }
      header[role="banner"] nav li:hover > .sub-menu {
        left: 0;
        opacity: 1; }
      header[role="banner"] nav > ul > li {
        max-width: 200px; }
        header[role="banner"] nav > ul > li > a {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          font-size: 16px; }
      header[role="banner"] nav .menu-item-has-children > a {
        padding-right: 35px; }
      header[role="banner"] nav .sub-toggled > .toggle-sub {
        transform: none; }
      header[role="banner"] nav .sub-menu,
      header[role="banner"] nav .toggle-sub {
        position: absolute; }
      header[role="banner"] nav .toggle-sub {
        top: 50%;
        right: 0;
        margin: 0;
        background: none;
        border: none;
        color: #ffffff;
        transform: translateY(-50%);
        pointer-events: none; }
      header[role="banner"] nav .sub-menu {
        display: block !important;
        top: 100%;
        left: -99999px;
        width: 100%;
        background: #ffffff;
        opacity: 0;
        transition-property: opacity;
        transition-timing-function: cubic-bezier(0.53, 0.86, 0.54, 0.99);
        transition-duration: 300ms, 200ms, 200ms, 200ms; }
        header[role="banner"] nav .sub-menu .toggle-sub {
          transform: translateY(-50%) rotate(90deg); }
        header[role="banner"] nav .sub-menu .sub-menu {
          top: 0; }
        header[role="banner"] nav .sub-menu li:hover > .sub-menu {
          right: 100%;
          left: auto; }
        header[role="banner"] nav .sub-menu li:hover > .toggle-sub {
          transform: translateY(-50%) rotate(-90deg); } }
  @media (min-width: 1100px) {
    header[role="banner"] nav > ul > li > a {
      z-index: 1;
      font-size: 21px; } }

.link-highlight {
  position: absolute;
  top: 90px;
  left: 0;
  width: 0;
  height: 10px;
  background: #828c4e;
  display: block;
  z-index: 0; }

/**
 * Banner
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.banner {
  height: 340px;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center; }
  @media (min-width: 1100px) {
    .banner {
      height: 705px; } }
  .banner .wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .banner .wrap img {
      width: 75px;
      height: 75px;
      margin: 31px 0; }
      @media (min-width: 1100px) {
        .banner .wrap img {
          width: 165px;
          height: 165px;
          margin: 55px 0; } }
  .banner h1 {
    color: #ffffff;
    letter-spacing: -2.5px; }
  .banner h2 {
    font-family: "Andada Italic", serif;
    font-size: 16px;
    color: #ffffff;
    margin: 10px 0; }
    @media (min-width: 1100px) {
      .banner h2 {
        font-size: 21px; } }
  .banner a[role="button"] {
    margin-top: 25px; }
    @media (min-width: 1100px) {
      .banner a[role="button"] {
        margin-top: 48px; } }
  .banner img {
    z-index: -1; }
  .banner .scrollto-button {
    cursor: pointer;
    width: 65px;
    height: 65px;
    position: absolute;
    bottom: 0;
    background-color: #828c4e;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 1100px) {
      .banner .scrollto-button {
        width: 130px;
        height: 130px; } }
    .banner .scrollto-button svg {
      font-size: 20px;
      color: #ffffff; }
      @media (min-width: 1100px) {
        .banner .scrollto-button svg {
          font-size: 35px; } }

.heading-small {
  font-size: 30px; }
  @media (min-width: 1100px) {
    .heading-small {
      font-size: 68px; } }

.large-banner {
  height: 430px; }
  @media (min-width: 1100px) {
    .large-banner {
      height: 970px; } }

/**
 * Footer
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
footer[role="contentinfo"] {
  position: relative;
  flex-shrink: 0;
  background: #2a2e37; }
  footer[role="contentinfo"] .footer-wrap {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 1800px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 1100px) {
      footer[role="contentinfo"] .footer-wrap {
        flex-direction: row;
        justify-content: center; } }

.footer-logo {
  width: 100%;
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 38px 0 30px; }
  .footer-logo a img {
    max-width: 120px;
    width: 120px;
    height: 100%; }
    @media (min-width: 1100px) {
      .footer-logo a img {
        max-width: 210px;
        width: 210px; } }
  @media (min-width: 1100px) {
    .footer-logo {
      width: 210px;
      max-width: 210px;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0; } }
  @media (min-width: 1716px) {
    .footer-logo {
      left: 130px; } }

.site-logo-footer p {
  display: none; }

.footer-content {
  margin: 0; }
  @media (min-width: 1100px) {
    .footer-content {
      margin: 45px 0;
      padding-left: 210px; } }
  @media (min-width: 1440px) {
    .footer-content {
      padding-left: 0; } }
  .footer-content .address {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 35px; }
    @media (min-width: 1100px) {
      .footer-content .address {
        flex-direction: row;
        margin: 0; } }
    .footer-content .address a,
    .footer-content .address p {
      font-family: "Andada Regular", serif;
      font-size: 16px;
      color: #ffffff;
      padding: 0 5px;
      margin: 0; }
      @media (min-width: 1440px) {
        .footer-content .address a,
        .footer-content .address p {
          font-size: 18px; } }
    .footer-content .address p {
      font-weight: 600; }
    .footer-content .address address {
      max-width: 175px;
      text-align: center; }
      @media (min-width: 1100px) {
        .footer-content .address address {
          max-width: none;
          text-align: unset; } }
    .footer-content .address .divider-dot {
      display: none; }
      @media (min-width: 1200px) {
        .footer-content .address .divider-dot {
          display: inline-block; } }
  .footer-content .copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
    @media (min-width: 1100px) {
      .footer-content .copyright {
        margin-top: 10px; } }
    .footer-content .copyright a,
    .footer-content .copyright p {
      max-width: 225px;
      font-family: "Andada Regular", serif;
      font-size: 16px;
      color: #a3a4a7; }
      @media (min-width: 1440px) {
        .footer-content .copyright a,
        .footer-content .copyright p {
          font-size: 18px; } }
    .footer-content .copyright p {
      margin: 0; }
      @media (min-width: 1100px) {
        .footer-content .copyright p {
          margin-bottom: 0;
          max-width: none; } }
    .footer-content .copyright .divider-dot:after {
      color: #a3a4a7; }

/**
 * Column Shortcodes Plugin Style Overrides
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
@media (min-width: 600px) {
  .one_half.last_column {
    width: 48%;
    margin-left: 2%; } }

@media (min-width: 600px) {
  .one_third {
    width: 31.3%;
    margin-left: 2%; } }

@media (min-width: 600px) {
  .one_third:first-child {
    width: 33.3%;
    margin-left: 0; } }

/**
 * Page Partials
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * 404 Page Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.error404 .banner {
  min-height: 100vh; }
  .error404 .banner:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(43, 47, 56, 0.8); }
  .error404 .banner .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .error404 .banner h1,
  .error404 .banner h2 {
    color: #ffffff;
    padding: 1.6667rem; }

/**
 * Thank You Page Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.thank-you .banner {
  min-height: 100vh; }
  .thank-you .banner:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(43, 47, 56, 0.8); }
  .thank-you .banner .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .thank-you .banner h1,
  .thank-you .banner h2 {
    color: #ffffff;
    padding: 1.6667rem; }

/**
 * About Page Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
@media (min-width: 768px) {
  .about-page .image-quote .quote-divider {
    width: 85%; } }

@media (min-width: 768px) {
  .about-page .image-quote .quote-wrapper h6 {
    font-size: 45px;
    line-height: 60px;
    max-width: 100%;
    text-align: center; } }

@media (min-width: 1100px) {
  .about-page .image-quote .quote-wrapper h6 {
    font-size: 65px;
    line-height: 80px;
    margin: 25px 0; } }

/**
 * Contact Page Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.contact-page header {
  height: 170px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden; }
  @media (min-width: 1100px) {
    .contact-page header {
      height: 490px; } }
  .contact-page header .wrap {
    max-width: 1800px; }
    .contact-page header .wrap h1,
    .contact-page header .wrap h2 {
      color: white; }
    .contact-page header .wrap h1 {
      font-size: 50px;
      letter-spacing: -2.5px; }
      @media (min-width: 1100px) {
        .contact-page header .wrap h1 {
          font-size: 94px; } }
    .contact-page header .wrap h2 {
      font-family: "Andada Italic", serif;
      font-size: 16px;
      max-width: 211px;
      margin-top: 15px;
      font-size: 21px; }
      @media (min-width: 1100px) {
        .contact-page header .wrap h2 {
          font-size: 36px;
          max-width: none;
          margin-top: 20px; } }
  .contact-page header img {
    z-index: -1; }

@media (min-width: 1100px) {
  .contact-page section {
    display: flex;
    flex-direction: row; } }

.contact-page .contact-info {
  margin-top: 30px; }
  @media (min-width: 1100px) {
    .contact-page .contact-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 35%;
      margin: 135px 0 0; } }
  .contact-page .contact-info h2 {
    display: none;
    font-size: 54px; }
    @media (min-width: 1100px) {
      .contact-page .contact-info h2 {
        display: block;
        max-width: 400px;
        margin-bottom: 25px; } }
  @media (min-width: 1100px) {
    .contact-page .contact-info .icon-links {
      max-width: 400px; } }
  .contact-page .contact-info .icon-links a {
    display: flex;
    align-items: center;
    font-family: "Andada Bold", serif;
    font-size: 14px;
    color: #9b9b9b;
    line-height: 2.29;
    margin: 15px 0; }
    @media (min-width: 1100px) {
      .contact-page .contact-info .icon-links a {
        margin: 37px 0; } }
    @media (min-width: 1200px) {
      .contact-page .contact-info .icon-links a {
        font-size: 21px; } }
  .contact-page .contact-info .icon-links .circle {
    width: 30px;
    height: 30px;
    background-color: #828c4e;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px; }
    @media (min-width: 1200px) {
      .contact-page .contact-info .icon-links .circle {
        width: 40px;
        height: 40px; } }
  .contact-page .contact-info .icon-links svg {
    color: #ffffff; }

.contact-page .community-form {
  height: auto;
  position: relative; }
  @media (min-width: 1100px) {
    .contact-page .community-form {
      width: 65%;
      margin: 0 0 40px; } }
  @media (min-width: 1440px) {
    .contact-page .community-form {
      background-color: #828c4e;
      min-height: 800px;
      margin: 0; } }
  .contact-page .community-form #contact-form {
    background-color: #ffffff; }
    @media (min-width: 1100px) {
      .contact-page .community-form #contact-form {
        margin: 0; } }
    @media (min-width: 1440px) {
      .contact-page .community-form #contact-form {
        min-width: 870px;
        position: absolute;
        top: -210px;
        left: 50%;
        transform: translateX(-50%); } }
    @media (min-width: 1440px) {
      .contact-page .community-form #contact-form .wrap {
        width: 100%; } }
    .contact-page .community-form #contact-form header {
      height: 110px;
      background-color: #f6f6f6; }
      @media (min-width: 1100px) {
        .contact-page .community-form #contact-form header {
          height: 210px; } }
    .contact-page .community-form #contact-form h2 {
      font-size: 24px;
      margin: 16px 0 3px; }
      @media (min-width: 1100px) {
        .contact-page .community-form #contact-form h2 {
          font-size: 44px;
          margin: 50px 0 6px;
          padding: 0 20px; } }
    .contact-page .community-form #contact-form h3 {
      max-width: 156px;
      margin: 3px 0 16px; }
      @media (min-width: 1100px) {
        .contact-page .community-form #contact-form h3 {
          max-width: none;
          font-size: 21px;
          margin: 6px 0 50px; } }
    .contact-page .community-form #contact-form form {
      margin-top: 26px; }
      @media (min-width: 1440px) {
        .contact-page .community-form #contact-form form {
          margin-left: auto;
          margin-right: auto;
          width: 90%;
          max-width: 1200px;
          margin-top: 71px;
          margin-bottom: 69px; } }

/**
 * Service Single Page Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.page-template-service #content .banner .wrap {
  justify-content: center;
  position: relative; }
  @media (min-width: 1100px) {
    .page-template-service #content .banner .wrap .heading h2 {
      font-size: 32px; } }

.page-template-service #content .service-single .entry-content h3 {
  margin-top: 18px; }

.page-template-service #content .service-single .entry-content blockquote p {
  padding: 0 2.50005rem; }
  @media (min-width: 1100px) {
    .page-template-service #content .service-single .entry-content blockquote p {
      padding: 1.6667rem 2.50005rem; } }

.page-template-service #content .service-single .entry-content h4 {
  font-size: 18px;
  text-align: right;
  padding: 0 2.50005rem 1.6667rem;
  margin-top: 0; }
  @media (min-width: 1100px) {
    .page-template-service #content .service-single .entry-content h4 {
      font-size: 26px; } }

/**
 * Section Partials
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Contact form styling for form on all pages
 * except contact page--that form styling is under pages/contact
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.contact-form {
  margin-top: 45px;
  margin-bottom: 45px; }
  @media (min-width: 1100px) {
    .contact-form {
      margin: 150px 0; } }
  .contact-form .form-wrap {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 1170px; }
    @media (min-width: 1440px) {
      .contact-form .form-wrap {
        width: 100%; } }
  .contact-form header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .contact-form header h2,
    .contact-form header h3,
    .contact-form header img {
      margin: 15px 0; }
    .contact-form header img {
      width: 64px;
      height: 64px; }
      @media (min-width: 1100px) {
        .contact-form header img {
          width: 117px;
          height: 117px;
          margin-top: 0;
          margin-bottom: 25px; } }
    @media (min-width: 1100px) {
      .contact-form header h2 {
        font-size: 54px; } }
    .contact-form header h3 {
      font-family: "Andada Italic", serif;
      font-size: 16px;
      color: #828c4e; }
      @media (min-width: 1100px) {
        .contact-form header h3 {
          font-size: 21px; } }
  .contact-form form {
    margin-top: 35px; }
  .contact-form .gform_footer {
    display: flex;
    justify-content: center; }
    .contact-form .gform_footer button[type="submit"] {
      width: 90%;
      display: flex;
      justify-content: center; }
      @media (min-width: 640px) {
        .contact-form .gform_footer button[type="submit"] {
          width: 267px; } }

/**
 * Awards section
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.awards-section {
  background-color: #2b2f38; }

.awards-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 35px 0; }
  @media (min-width: 1100px) {
    .awards-wrapper {
      padding: 33.5px 0; } }

.award img {
  margin: 0;
  padding: 20px;
  max-width: 150px; }
  @media (min-width: 1100px) {
    .award img {
      margin: 40px;
      padding: 0;
      max-width: none; } }

/**
 * Section with image and quote box styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.image-quote {
  position: relative;
  margin: 1.6667rem 0; }
  @media (min-width: 2000px) {
    .image-quote {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      max-width: 1800px; } }
  .image-quote h5,
  .image-quote h6 {
    font-family: "Andada Italic", serif;
    color: #ffffff; }
  .image-quote .blue-block {
    width: 100%;
    height: 161px;
    position: absolute;
    background-color: #2b2f38;
    display: flex;
    justify-content: center;
    z-index: -1; }
    @media (min-width: 1100px) {
      .image-quote .blue-block {
        width: 609px;
        height: 580px; } }
    .image-quote .blue-block h5 {
      font-size: 16px;
      margin-top: 35px; }
      @media (min-width: 1100px) {
        .image-quote .blue-block h5 {
          display: none; } }
  .image-quote .image-quote-wrapper {
    padding-top: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 1130px; }
    @media (min-width: 1440px) {
      .image-quote .image-quote-wrapper {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        max-width: 1800px; } }
    .image-quote .image-quote-wrapper h5 {
      display: none; }
      @media (min-width: 1100px) {
        .image-quote .image-quote-wrapper h5 {
          display: block;
          font-size: 26px;
          margin-bottom: 44px; } }
  .image-quote .quote-wrapper {
    width: 100%;
    height: 290px;
    background-color: #828c4e;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .image-quote .quote-wrapper h6 {
      font-size: 18px;
      max-width: 195px;
      text-align: center;
      line-height: 30px; }
      @media (min-width: 1100px) {
        .image-quote .quote-wrapper h6 {
          font-size: 26px;
          max-width: 261px;
          line-height: 34px; } }
    .image-quote .quote-wrapper svg {
      max-height: 26px;
      margin: 10px 0; }
  .image-quote .quote-divider {
    width: 200px;
    height: 3px;
    background-color: #ffffff;
    margin: 10px 0; }
    @media (min-width: 1440px) {
      .image-quote .quote-divider {
        width: 300px; } }
  .image-quote .quote-mobile {
    display: flex; }
    @media (min-width: 1440px) {
      .image-quote .quote-mobile {
        display: none; } }
  .image-quote .quote-desktop {
    display: none; }
    @media (min-width: 1440px) {
      .image-quote .quote-desktop {
        width: 500px;
        height: 555px;
        display: flex;
        position: absolute;
        bottom: 8px;
        right: 0;
        outline: 18px solid #ffffff; } }
    @media (min-width: 2000px) {
      .image-quote .quote-desktop {
        width: 525px; } }
  .image-quote.bottom-text {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 1200px;
    padding: 44px 0; }
    @media (min-width: 1100px) {
      .image-quote.bottom-text {
        padding: 109px 0; } }
    .image-quote.bottom-text p {
      margin-top: 2.00004rem; }
  .image-quote.top-text {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 1800px;
    margin-bottom: 72px; }
    @media (min-width: 1100px) {
      .image-quote.top-text {
        margin-bottom: 34px; } }
    @media (min-width: 1100px) {
      .image-quote.top-text h3 {
        font-size: 26px; } }
    @media (min-width: 1600px) {
      .image-quote.top-text h3 {
        font-size: 36px; } }
    @media (min-width: 2000px) {
      .image-quote.top-text h3 {
        font-size: 52px; } }
    @media (min-width: 1100px) {
      .image-quote.top-text p {
        font-size: 21px; } }

/**
 * Details Slider Section styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
@media (min-width: 1100px) {
  .details-slider .details-slider-wrapper {
    display: flex;
    flex-direction: row; } }

@media (min-width: 1716px) {
  .details-slider .details-slider-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 1800px; } }

.details-slider .details {
  display: flex;
  justify-content: center;
  z-index: -1;
  background-color: #2b2f38; }
  @media (min-width: 1200px) {
    .details-slider .details {
      width: 40%;
      min-height: 740px; } }
  @media (min-width: 1440px) {
    .details-slider .details {
      width: 60%; } }
  .details-slider .details .wrap {
    padding: 85px 0 71px; }
    @media (min-width: 1200px) {
      .details-slider .details .wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
  .details-slider .details h2,
  .details-slider .details ul {
    color: #ffffff; }
    @media (min-width: 1100px) {
      .details-slider .details h2,
      .details-slider .details ul {
        max-width: 377px;
        padding-left: 20px; } }
    @media (min-width: 1440px) {
      .details-slider .details h2,
      .details-slider .details ul {
        max-width: 577px; } }
    .details-slider .details h2 li,
    .details-slider .details ul li {
      margin: 0.41668rem 0; }
  .details-slider .details h2 {
    margin-bottom: 18px; }
    @media (min-width: 1100px) {
      .details-slider .details h2 {
        font-size: 34px;
        margin-bottom: 28px; } }
    @media (min-width: 1440px) {
      .details-slider .details h2 {
        font-size: 54px;
        margin-bottom: 28px; } }

#details-slider .slider {
  top: 45px; }
  @media (min-width: 1100px) {
    #details-slider .slider {
      top: 133px; } }
  @media (min-width: 1200px) {
    #details-slider .slider {
      right: -75px; } }
  @media (min-width: 1440px) {
    #details-slider .slider {
      right: -75px; } }
  @media (min-width: 1600px) {
    #details-slider .slider {
      right: -200px; } }
  @media (min-width: 1600px) {
    #details-slider .slider {
      right: -150px; } }

/**
 * Section with text and image collage
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.image-collage {
  display: flex;
  flex-direction: column;
  margin-top: 3.3334rem;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1800px; }
  @media (min-width: 640px) {
    .image-collage {
      margin-bottom: 3.3334rem; } }
  @media (min-width: 1100px) {
    .image-collage {
      flex-direction: row; } }
  @media (min-width: 1440px) {
    .image-collage {
      justify-content: center;
      margin-top: 8.3335rem;
      margin-bottom: 8.3335rem; } }
  .image-collage .intro {
    position: relative; }
    @media (min-width: 1440px) {
      .image-collage .intro {
        margin-left: 150px; } }
  .image-collage .icon {
    display: none; }
    @media (min-width: 1440px) {
      .image-collage .icon {
        display: block;
        position: absolute;
        left: -150px;
        margin-top: 1.11113rem; } }
    .image-collage .icon img {
      max-width: 130px;
      height: 100%; }
  @media (min-width: 1100px) {
    .image-collage .entry-content blockquote p {
      font-size: 21px; } }
  @media (min-width: 1100px) {
    .image-collage .entry-content h2 {
      font-size: 66px;
      max-width: 525px; } }
  @media (min-width: 1100px) {
    .image-collage .entry-content p {
      max-width: 525px; } }
  .image-collage .images-small {
    max-width: 350px;
    min-height: 315px;
    position: relative;
    margin: 50px auto; }
    @media (min-width: 460px) {
      .image-collage .images-small {
        margin: 50px auto 0; } }
    @media (min-width: 640px) {
      .image-collage .images-small {
        max-width: 550px;
        min-height: 375px; } }
    @media (min-width: 1024px) {
      .image-collage .images-small {
        max-width: 700px; } }
    .image-collage .images-small .feature-image,
    .image-collage .images-small .left-image,
    .image-collage .images-small .middle-image {
      position: absolute;
      display: flex;
      flex-direction: column; }
      .image-collage .images-small .feature-image img,
      .image-collage .images-small .left-image img,
      .image-collage .images-small .middle-image img {
        max-width: 65%; }
        @media (min-width: 640px) {
          .image-collage .images-small .feature-image img,
          .image-collage .images-small .left-image img,
          .image-collage .images-small .middle-image img {
            max-width: 100%; } }
        @media (min-width: 1100px) {
          .image-collage .images-small .feature-image img,
          .image-collage .images-small .left-image img,
          .image-collage .images-small .middle-image img {
            max-width: 65%; } }
        @media (min-width: 1440px) {
          .image-collage .images-small .feature-image img,
          .image-collage .images-small .left-image img,
          .image-collage .images-small .middle-image img {
            max-width: 85%; } }
    .image-collage .images-small .left-image {
      top: 0;
      left: 0; }
      @media (min-width: 1440px) {
        .image-collage .images-small .left-image {
          left: -150px; } }
      @media (min-width: 1024px) {
        .image-collage .images-small .left-image img {
          width: 457px; } }
      @media (min-width: 1650px) {
        .image-collage .images-small .left-image img {
          max-width: 100%;
          height: 300px; } }
    .image-collage .images-small .middle-image {
      align-items: flex-end;
      top: 36px;
      right: 0; }
      @media (min-width: 1440px) {
        .image-collage .images-small .middle-image {
          top: 13px; } }
      @media (min-width: 1440px) {
        .image-collage .images-small .middle-image {
          right: -15px; } }
      @media (min-width: 1716px) {
        .image-collage .images-small .middle-image {
          top: 138px; } }
      @media (min-width: 1440px) {
        .image-collage .images-small .middle-image img {
          max-width: 100%; } }
    .image-collage .images-small .feature-image {
      bottom: 0;
      left: 47px; }
      .image-collage .images-small .feature-image img {
        max-width: 65%; }
        @media (min-width: 1100px) {
          .image-collage .images-small .feature-image img {
            max-width: 100%; } }
    @media (min-width: 640px) {
      .image-collage .images-small .feature-image {
        display: none; } }
  .image-collage .images-large {
    display: none;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 640px) {
      .image-collage .images-large {
        display: flex;
        margin: 0 auto; } }
    @media (min-width: 1100px) {
      .image-collage .images-large {
        min-width: 544px;
        margin: 0 0 0 75px; } }

.home #content .entry-content h3 {
  margin: 1.11113rem 0 0; }

/**
 * Slogan section
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.slogan .logo-stacked {
  display: flex;
  justify-content: center;
  margin: 55px 0 42px; }
  @media (min-width: 1100px) {
    .slogan .logo-stacked {
      margin: 140px 0 65px; } }
  .slogan .logo-stacked img {
    width: 107px;
    height: 100px; }
    @media (min-width: 1100px) {
      .slogan .logo-stacked img {
        width: 199px;
        height: 188px; } }

.slogan .entry-content {
  display: flex;
  justify-content: center; }
  .slogan .entry-content blockquote {
    max-width: 260px;
    font-family: "Andada Bold", serif;
    font-size: 24px;
    font-style: normal;
    color: #2a2e37;
    text-align: center;
    margin-bottom: 44px; }
    @media (min-width: 1100px) {
      .slogan .entry-content blockquote {
        max-width: 800px;
        font-size: 54px;
        line-height: 70px;
        letter-spacing: -2px; } }

.slogan .team-image {
  margin-bottom: -8px; }
  @media (min-width: 2000px) {
    .slogan .team-image {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      max-width: 1800px; } }
  .slogan .team-image img {
    min-width: 100%; }

/**
 * Quote Slider Section styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.quote-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 3.3334rem 0; }
  @media (min-width: 1100px) {
    .quote-slider {
      height: 975px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      max-width: 1600px; } }
  .quote-slider .quote-image {
    width: 281px;
    height: 176px;
    position: relative; }
    @media (min-width: 460px) {
      .quote-slider .quote-image {
        width: 425px; } }
    @media (min-width: 640px) {
      .quote-slider .quote-image {
        width: 600px; } }
    @media (min-width: 1100px) {
      .quote-slider .quote-image {
        width: 900px;
        height: 600px;
        position: absolute;
        top: 0;
        left: 0; } }
    @media (min-width: 1100px) {
      .quote-slider .quote-image {
        width: 900px;
        height: 600px;
        position: absolute;
        top: 0;
        left: 0; } }
    @media (min-width: 1600px) {
      .quote-slider .quote-image {
        width: 1170px;
        height: 756px; } }
  .quote-slider .quote {
    width: 281px;
    height: 305px;
    background-color: #828c4e;
    margin-top: 25px; }
    @media (min-width: 460px) {
      .quote-slider .quote {
        width: 425px;
        height: 425px; } }
    @media (min-width: 640px) {
      .quote-slider .quote {
        width: 600px; } }
    @media (min-width: 1100px) {
      .quote-slider .quote {
        position: absolute;
        bottom: 0;
        right: 0; } }
    @media (min-width: 1600px) {
      .quote-slider .quote {
        width: 720px; } }
  .quote-slider .background-image img {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 60%;
    width: 40%;
    height: 80%;
    z-index: -3; }
  .quote-slider .background-image::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.25); }

/**
 * Services Listing Section styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.services-listing {
  background-color: #2b2f38;
  padding: 40px 0; }
  .services-listing .services-listing-wrap {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 1800px; }
  .services-listing .services-listing-title {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 1800px;
    text-align: center;
    padding: 45px 0; }
    @media (min-width: 1100px) {
      .services-listing .services-listing-title {
        text-align: left; } }
    .services-listing .services-listing-title h2 {
      color: #ffffff; }
      @media (min-width: 1100px) {
        .services-listing .services-listing-title h2 {
          letter-spacing: -2px; } }
  .services-listing .card-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%; }
    @media (min-width: 640px) {
      .services-listing .card-grid {
        flex-direction: row; } }
  .services-listing .service-card {
    display: flex;
    flex-direction: column;
    min-width: 281px;
    max-width: 281px;
    min-height: 283px;
    max-height: 283px;
    margin: 10px 15px;
    background-color: #ffffff;
    box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.35); }
    @media (min-width: 1100px) {
      .services-listing .service-card {
        min-width: 375px;
        max-width: 375px;
        min-height: 405px;
        max-height: 405px;
        margin: 20px 18px; } }
    @media (min-width: 2000px) {
      .services-listing .service-card {
        min-width: 403px;
        max-width: 403px; } }
  .services-listing .card-image {
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden; }
    @media (min-width: 1100px) {
      .services-listing .card-image {
        height: 290px; } }
  .services-listing .card-text {
    width: 100%;
    height: 84px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; }
    @media (min-width: 1100px) {
      .services-listing .card-text {
        height: 115px; } }
    .services-listing .card-text h2 {
      font-size: 21px; }
      @media (min-width: 1100px) {
        .services-listing .card-text h2 {
          font-size: 30px; } }
    .services-listing .card-text p {
      font-family: "Andada Italic", serif;
      font-size: 16px;
      color: #828c4e;
      margin-top: 5px; }
      @media (min-width: 1100px) {
        .services-listing .card-text p {
          font-size: 21px; } }
