/**
 * Social icon navigation
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.socials {
	font-size: 0;
	margin: 0 bsu(-1/3);

	a {
		display: inline-block;
		padding: bsu(1/3);
	}

	.svg-inline--fa {
		font-size: $global-font-size;
	}
}
