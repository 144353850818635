/**
 * Navigation
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
$toggle-width: 40px;
$toggle-space: 10px;

header[role="banner"] nav {
	position: fixed;
	top: $header-height;
	right: 0;
	width: 100%;
	background: $white;
	overflow: hidden;
	transform: translateY(-100%) translateY(-1px);
	transition-property: transform, top;
	@include transition-custom(400ms);

	&,
	.toggle-sub,
	a {
		display: block;
	}

	&.toggled {
		transform: translateY(0%);
	}
	// menu too tall for window
	// @see js/foot/navigation-animate.js
	&.scroll-y {
		bottom: 100%;
		overflow-y: scroll;

		&.toggled {
			bottom: 0;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		width: 100%;
	}

	li {
		display: flex;
		flex-flow: row wrap;
		position: relative;
		padding-left: bsu(1/3);
	}

	.menu-item-has-children > a {
		width: calc(100% - #{$toggle-width + $toggle-space});
	}

	.sub-toggled {
		> .toggle-sub {
			transform: rotateX(180deg);
		}
	}

	a {
		flex: 1 0 auto;
		padding: bsu(1/4) bsu(1/2);
		width: 100%;
		text-decoration: none;
		color: $secondary;
		font-family: $font-serif-bold;
	}
	// triangle sub-menu toggler
	.toggle-sub {
		flex: 0 1 auto;
		// flex: 0 1 $toggle-width;
		width: $toggle-width;
		margin-left: $toggle-space;
		padding: 0;
	}

	.sub-menu {
		order: 1;
		display: none;

		a {
			color: $gray;
		}
	}
	/**
	 * Main Navigation - Desktop "menu break"
	 */
	@include breakpoint(menu-break) {
		flex: 0 0 auto;
		display: flex;
		position: static;
		width: auto;
		transform: none;
		overflow: visible;

		ul {
			display: flex;
			flex-flow: row nowrap;
		}

		li:hover {
			> .toggle-sub {
				transform: translateY(-50%) rotateX(180deg);
			}

			> .sub-menu {
				left: 0;
				opacity: 1;
			}
		}
		// restrict top-level link width + center them
		> ul > li {
			max-width: 200px;

			> a {
				@include flex-row(true);
				font-size: $global-font-size;
			}
		}

		.menu-item-has-children > a {
			padding-right: $toggle-width - ($toggle-space / 2);
		}
		// undo mobile accordion flip
		.sub-toggled > .toggle-sub {
			transform: none;
		}

		.sub-menu,
		.toggle-sub {
			position: absolute;
		}

		.toggle-sub {
			top: 50%;
			right: 0;
			margin: 0;
			background: none;
			border: none;
			color: $white;
			transform: translateY(-50%);
			pointer-events: none;
		}

		.sub-menu {
			display: block !important; // override slideToggle display: none
			top: 100%;
			left: -99999px;
			width: 100%;
			background: $white;
			opacity: 0;
			transition-property: opacity;
			@include transition-custom(300ms);

			.toggle-sub {
				transform: translateY(-50%) rotate(90deg);
			}

			.sub-menu {
				top: 0;
			}

			li:hover {
				> .sub-menu {
					right: 100%;
					left: auto;
				}

				> .toggle-sub {
					transform: translateY(-50%) rotate(-90deg);
				}
			}
		}
		// .sub-menu
	}
	// menu break
	@include breakpoint(menu-break) {
		> ul > li {
			> a {
				z-index: 1;
				font-size: 21px;
			}
		}
	}
}

.link-highlight {
	position: absolute;
	top: 90px;
	left: 0;
	width: 0;
	height: 10px;
	background: $primary;
	display: block;
	z-index: 0;
}
