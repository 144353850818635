/**
 * Mixins
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
/**
 * Generic wrapper
 */
@mixin wrap($max-width : $wrap-max, $width : $wrap-width) {
  margin-left: auto;
  margin-right: auto;
  width: $width;
  max-width: $max-width;
}
/**
 * Object-fit "cover"
 */
@mixin cover-image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/**
 * Centered block
 */
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/**
 * Centered element trick!
 */
@mixin absolute-center($stretch: false, $extra-transforms: "", $top: 50%, $left: 50%) {
  position: absolute;
  top: $top;
  left: $left;
  width: auto;
  max-width: 1000%;
  height: auto;
  transform: translateX(-50%) translateY(-50%) unquote($extra-transforms);
  @if $stretch {
    min-width: 100%;
    min-height: 100%;
  }
}
/**
 * Make a flex "row"
 */
@mixin flex-row($align-center: false, $inline-flex: false) {
  @if $inline-flex {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-flow: row nowrap;
  @if $align-center {
    align-items: center;
  }
}
/**
 * Font smoothing
 */
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/**
 * Kill font smoothing!
 */
@mixin no-font-smoothing {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
/**
 * Inline text link
 */
@mixin inline-link {
  color: $primary;
  border-bottom: 1px solid transparent;
  transition-property: color, border-bottom-color;
  @include transition-ease-in-out;

  &:hover {
    color: $primary-lighter;
    border-bottom-color: $primary-lighter;
  }
}
/**
 * Styled list
 */
@mixin list {
  li {
    position: relative;
    margin: bsu(1/2) 0;
    padding-left: bsu(1);
    // counter / icons
    &:after,
    &:before,
    .svg-inline--fa {
      color: $primary;
      position: absolute;
      left: 0;
    }
  }
  // nested
  ol,
  ul {
    margin-top: 0;
  }

  a {
    @include inline-link;
  }
}
/**
 * Bulleted list
 *
 * Icons inserted with js/foot/list-icons.js
 */
@mixin bulleted-list {
  li {
    // icons
    &:after,
    &:before,
    .svg-inline--fa {
      top: 9px;
    }
  }
}
/**
 * Numbered list
 */
@mixin numbered-list {
  &,
  ol {
    counter-reset: section;
  }

  li:before {
    counter-increment: section;
    content: counters(section, ".") ".";
  }
  // nested
  ol li:before {
    font-size: 0.9rem;
  }
}
/**
 * Remove bottom margin if last child
 */
@mixin no-bottom-if-last {
  &:last-child {
    margin-bottom: 0;
  }
}
/**
 * Remove top margin if first child
 */
@mixin no-top-if-first {
  &:first-child:not(p) {
    margin-top: 0;
  }
}
/**
 * WYSIWYG content format
 */
@mixin wysiwyg {
  // base spacing
  > ul,
  > ol,
  blockquote,
  form,
  h2,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: bsu(1);
  }

  [role="button"] {
    margin: bsu(1) bsu(1/2) 0 0;
  }
  // no space if at the beginning or end
  > h3,
   > h4,
   > h5,
   > h6,
   > ol,
   > p,
   > ul,
  > h2,
  blockquote,
  blockquote p,
  h1 {
    @include no-bottom-if-last;
    @include no-top-if-first;
  }
  // lists
  > ul,
  > ol {
    @include list;
  }

  > ul {
    @include bulleted-list;
  }

  > ol {
    @include numbered-list;
  }
}
// wysiwyg
/**
 * Responsive embed container
 */
@mixin responsive-embed-container {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 440px;
  overflow: hidden;

  embed,
  iframe,
  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
/**
 * Fluid Property!
 */
@mixin fp($property, $min, $max, $start: 320, $end: 1280, $clip: true, $clipAtStart: true, $clipAtEnd: true) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @if $clip and $clipAtStart {
    @media (max-width: #{$start + 0px}) {
      #{$property}: $min + 0px;
    }
  }
  @if $clip and $clipAtEnd {
    @media (min-width: #{$end + 0px}) {
      #{$property}: $max + 0px;
    }
  }
  #{$property}: $formula;
}
// fp()
@function fp-calc($min, $max, $start: 320, $end: 1280) {
  $multiplier: ($max - $min) / ($end - $start) * 100;
  $adder: ($min * $end - $max * $start) / ($end - $start);
  $formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});
  @return $formula;
}
/**
 * Overlay
 */
@mixin overlay($z-index: 0, $top: 0, $right: 0, $bottom: 0, $left: 0) {
  content: '';
  display: block;
  position: absolute;
  z-index: $z-index;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
/**
 * Generic transition
 */
@mixin transition-ease-in-out($speed: 200ms) {
  transition-timing-function: ease-in-out;
  transition-duration: $speed;
}
/**
 * Custom transition
 */
@mixin transition-custom($speed1: 200ms, $speed2: 200ms, $speed3: 200ms, $speed4: 200ms) {
  transition-timing-function: cubic-bezier(.53,.86,.54,.99);
  transition-duration: $speed1, $speed2, $speed3, $speed4;
}
/**
 * Clearfix
 */
@mixin clearfix {
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}
// clearfix()
/*
	SCREEN READERS
*/
@mixin screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;

  &:focus {
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: $black;
    display: block;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
  }
}
