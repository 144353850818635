/**
 * Header
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
header[role="banner"] {
	position: fixed;
	z-index: 50;
	top: 0;
	right: 0;
	left: 0;
	transition-property: box-shadow;
	transition-duration: 0.3s;
	transition-timing-function: cubic-bezier(.53,.86,.54,.99);

	.logo-toggle,
	.site-logo {
		@include flex-row;
	}
	// logo / mobile toggle wrap
	.logo-toggle {
		flex: 1 0 auto;
		position: relative;
		z-index: 1;
		padding-left: bsu(1/3);
		height: $header-height;
		background: $white;
	}
	// logo
	.site-logo {
		align-items: center;
		margin-right: auto;

		img,
		svg {
			max-height: 35px;
			@include breakpoint (medium) {
				max-height: $header-height - 20px;
			}
			@include breakpoint (menu-break) {
				max-height: 82px;
			}
		}

		p {
			display: none;
		}
	}

	.logo {
		width: 120px;
		@include breakpoint (medium) {
			width: 160px;
		}
		@include breakpoint (menu-break) {
			width: 249px;
		}
	}

	.nav-cta {
		display: none;
		align-items: center;
		background: $white;
		margin: 0 0 0 20px;

		[role="button"] {
			padding: 5px 30px;
		}
	}
	@include breakpoint(menu-break) {
		@include flex-row;
		height: $header-height-desk;
		background: $white;
		padding: 0 30px;

		.logo-toggle {
			flex: 0 1 auto;
			margin-right: auto;
			padding: 0;
			height: auto;
			background: none;
		}

		.site-logo svg {
			max-height: $header-height-desk - 20px;
		}

		.svg {
			width: 250px;
		}

		.nav-cta {
			display: flex;

			[role="button"] {
				font-size: $global-font-size;
				letter-spacing: 0.5px;
			}
		}
	}
	@include breakpoint (vlarge) {
		padding: 0 130px;

		.nav-cta {
			display: flex;

			[role="button"] {
				font-size: 21px;
			}
		}
	}
	/**
	 * Admin bar fix - for "non-static" headers
	 */
	body.admin-bar & {
		@include breakpoint(600px) {
			top: 46px !important;
		}
		@include breakpoint(782px) {
			top: 32px !important;
		}
	}
}
/**
 * Mobile navigation toggle
 */
#menu-toggle {
	width: $header-height;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 35px;
		height: 35px;
		color: $primary;
	}
	@include breakpoint(menu-break) {
		display: none;
	}
}
/**
 * Hamburger Menu
 */
.hamburger-wrapper {
	cursor: pointer;
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	div {
		width: 45%;
		height: 2px;
		background-color: $primary;
		position: absolute;
	}

	.top {
		top: 35%;
	}

	.middle {
		top: 50%;
	}

	.bottom {
		top: 65%;
	}
}
/**
 * Opened navigation overlay
 */
.nav-overlay {
	@include overlay(-1);
	display: none;
	position: fixed;
	cursor: pointer;
	background: $primary;
	opacity: 0.9;
	@include breakpoint(menu-break) {
		display: none !important; // override fadeToggle
	}
}
