/**
 * Form
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
form {
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
}

input,
label,
option,
select,
textarea {
	display: block;
	width: 100%;
	font-family: $font-sans-serif;
	font-weight: 700;
	font-size: 14px;
	color: $form-text;
	@include breakpoint (menu-break) {
		font-size: $global-font-size;
	}

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: $form-text;
	}
}

input,
select,
textarea {
	line-height: $line-height-body;
	border: 3px solid $form-border;
	padding-left: 25px;
	margin-bottom: 15px;
	padding: rem-calc(10.5) 30px;
	background: $white;
	border-radius: 0;
	transition-property: border-color;
	@include transition-ease-in-out;
	@include breakpoint (menu-break) {
		padding: rem-calc(14) 30px;
		margin-bottom: 30px;
	}

	&:focus {
		border-color: $form-text;
	}
}

textarea {
	resize: vertical;
	height: 140px;
}

[type="search"] {
	-webkit-appearance: textfield;
}

select {
	appearance: none;
}

select {
	padding-right: 52px;
	background-image: url("../images/chevron-down.svg");
	background-repeat: no-repeat;
	background-position: calc(100% - 20px) 50%;
	background-size: $global-font-size;

	&::-ms-expand {
		display: none;
	}
}

label {
	margin-bottom: bsu(1/4);
	padding-left: rem-calc(14);
	@include screen-reader-text;
}
/**
 * Checkboxes/radios
 */
[type="checkbox"],
[type="radio"] {
	position: absolute;
	width: 4px;
	height: 4px;
	opacity: 0;
}

[type="checkbox"] + label,
[type="radio"] + label,
.woocommerce-form__label-for-checkbox {
	position: relative;
	margin: 0 0 0 rem-calc(10);
	padding: rem-calc(6) 0 rem-calc(6) rem-calc(40);
	width: auto;
	cursor: pointer;
	transition-property: color;

	.gfield_checkbox &,
	.gfield_radio & {
		margin-left: rem-calc(8);
	}

	&,
	&:after,
	&:before {
		@include transition-ease-in-out;
	}

	&:after,
	&:before {
		content: '';
		position: absolute;
	}

	&:before {
		top: rem-calc(5);
		left: 0;
		margin-right: rem-calc(14);
		width: 25px;
		height: 25px;
		border: $border-width solid $gray;
		transition-property: border-color;
	}

	&:after {
		top: 12px;
		left: 7px;
		width: 11px;
		height: 11px;
		transform: scale(0);
		transition-property: background-color, transform;
	}

	&:hover {
		color: $primary;
	}
}
// checkbox and radio labels
// focus

[type="checkbox"]:focus + label,
[type="radio"]:focus + label {
	&:before {
		border-color: $primary;
	}
}
// checked

[type="checkbox"]:checked + label,
[type="radio"]:checked + label,
.woocommerce-form__label-for-checkbox.input-checked {
	&:after {
		transform: scale(1);
		background: $primary;
	}
}
// radios

[type="radio"] + label:before,
[type="radio"] + label:after {
	border-radius: 9999px;
}

.fields > li ul,
.fields > li ul,
ul.gfield_checkbox,
ul.gfield_radio {
	margin: bsu(1/4) 0 bsu(2/3);
}
// datepicker field

.ginput_container_date {
	position: relative;

	.svg-inline--fa {
		position: absolute;
		top: 50%;
		right: rem-calc(20);
		font-size: rem-calc(20);
		transform: translateY(-50%);
		color: $gray;
	}
}
// submit

[type="submit"] {
	margin-top: bsu(1/3);
}
/**
 * Validation
 */
.validation-message,
.validation_error,
.validation_message {
	color: $red;
	font-size: $global-font-size;
}

.validation_error {
	margin-bottom: bsu(1);
	padding: rem-calc(15) rem-calc(20);
	text-align: center;
	border: $border-width solid $red;
}

.validation-message,
.validation_message {
	margin-top: bsu(-1/3);
	margin-bottom: bsu(3/4);
	padding-left: rem-calc(13);

	.gfield_checkbox &,
	.gfield_radio & {
		padding-left: rem-calc(12);
	}

	.svg-inline--fa {
		margin-right: rem-calc(7);
	}
	@include breakpoint(medium) {
		margin-top: bsu(-1/2);
	}
}

.fields > li.error,
.gfield_error {
	input,
	select,
	textarea {
		border-color: $red;
	}
}
/**
 * Layout
 */
.fields,
.gform_fields {
	font-size: 0;
	margin: 0 bsu(-1/2);
}

.fields > li,
.gfield {
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding: 0 15px;
	width: 100%;
	@include breakpoint(medium) {
		&.one-half {
			width: 50%;
		}
	}
}
