/**
 * Section with image and quote box styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.image-quote {
	position: relative;
	margin: bsu(1) 0;
	@include breakpoint (vvlarge) {
		@include wrap(1800px);
	}

	h5,
	h6 {
		font-family: $font-serif-italic;
		color: $white;
	}

	.blue-block {
		width: 100%;
		height: 161px;
		position: absolute;
		background-color: $secondary;
		display: flex;
		justify-content: center;
		z-index: -1;
		@include breakpoint (menu-break) {
			width: 609px;
			height: 580px;
		}

		h5 {
			font-size: $global-font-size;
			margin-top: 35px;
			@include breakpoint (menu-break) {
				display: none;
			}
		}
	}

	.image-quote-wrapper {
		padding-top: 100px;
		@include wrap(1130px);
		@include breakpoint (xxlarge) {
			@include wrap(1800px);
		}

		h5 {
			display: none;
			@include breakpoint (menu-break) {
				display: block;
				font-size: 26px;
				margin-bottom: 44px;
			}
		}
	}

	.quote-wrapper {
		width: 100%;
		height: 290px;
		background-color: $primary;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h6 {
			font-size: 18px;
			max-width: 195px;
			text-align: center;
			line-height: 30px;
			@include breakpoint (menu-break) {
				font-size: 26px;
				max-width: 261px;
				line-height: 34px;
			}
		}

		svg {
			max-height: 26px;
			margin: 10px 0;
		}
	}

	.quote-divider {
		width: 200px;
		height: 3px;
		background-color: $white;
		margin: 10px 0;
		@include breakpoint (xxlarge) {
			width: 300px;
		}
	}

	.quote-mobile {
		display: flex;
		@include breakpoint (xxlarge) {
			display: none;
		}
	}

	.quote-desktop {
		display: none;
		@include breakpoint (xxlarge) {
			width: 500px;
			height: 555px;
			display: flex;
			position: absolute;
			bottom: 8px;
			right: 0;
			outline: 18px solid $white;
		}
		@include breakpoint (vvlarge) {
			width: 525px;
		}
	}

	&.bottom-text {
		@include wrap(1200px);
		padding: 44px 0;
		@include breakpoint (menu-break) {
			padding: 109px 0;
		}

		p {
			margin-top: bsu(1.2);
		}
	}

	&.top-text {
		@include wrap(1800px);
		margin-bottom: 72px;
		@include breakpoint (menu-break) {
			margin-bottom: 34px;
		}

		h3 {
			@include breakpoint (menu-break) {
				font-size: 26px;
			}
			@include breakpoint (1600px) {
				font-size: 36px;
			}
			@include breakpoint (2000px) {
				font-size: 52px;
			}
		}

		p {
			@include breakpoint (menu-break) {
				font-size: 21px;
			}
		}
	}
}
