/**
 * Page Loader Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.page-loader {
	width: 100%;
	height: 100%;
	background-color: $primary;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}
