/**
 * Slider Styles
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
.text-slider {
	width: 100%;
	height: 100%;
	position: relative;

	.main-carousel {
		height: 100%;
	}

	.carousel-cell {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 20px;
		@include breakpoint (medium) {
			padding: 50px;
		}

		blockquote,
		p {
			color: $white;
			font-size: 18px;
			@include breakpoint (medium) {
				font-size: 21px;
			}
			@include breakpoint (1600px) {
				font-size: 26px;
			}
		}

		p {
			font-family: $font-serif-bold;
			font-weight: 600;
		}
	}

	.flickity-prev-next-button {
		cursor: pointer;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-color: $secondary;
		border-radius: 0;
		padding: 0 5px;
		@include breakpoint (medium) {
			width: 50px;
			height: 110px;
		}

		&.previous {
			left: -14px;
			@include breakpoint (medium) {
				left: -24px;
			}
		}

		&.next {
			right: -14px;
			@include breakpoint (medium) {
				right: -24px;
			}
		}

		.flickity-button-icon {
			fill: $white;
		}

		svg {
			width: 12px;
			height: 25px;
			@include breakpoint (medium) {
				width: 25px;
				height: 67px;
			}
		}
	}
}
